import React, { useState, useEffect } from "react";

const CheckAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [label, setLabel] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [cash, setCash] = useState(null);
  const [pan, setPan] = useState(null);
  const [kycStatus, setKycStatus] = useState(0);
  const [walr, setWalr] = useState();
  const [residex, setResidex] = useState();
  const [loaderCheckAuth, setLoaderCheckAuth] = useState(true);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  const admin = { "scdnd.estates@gmail.com": "Whole-time Director" };

  const agent = {
    "contactrajarshi@gmail.com": "Agent",
    "ritoja.das96@gmail.com": "Agent",
  };

  const employee = {
    "info.chandan16@gmail.com": "Frontend Developer",
    "webinfra.scdnd@gmail.com": "Web Developer",
    "frontend.scdnd@gmail.com": "Frontend Developer",
    "digital.scdnd@gmail.com": "Fullstack Developer",
    "compliance.scdnd@gmail.com": "Compliance Officer",
    "statistics.scdnd@gmail.com": "Quality & Statistics",
    "marketing.scdnd@gmail.com": "Marketing Director",
  };

  useEffect(() => {
    setLoaderCheckAuth(true);
    fetch(`https://api-scdndestates.com/api/check/`, {
      method: "POST",
      body: JSON.stringify({
        access_token: "access_token=" + getCookie("access_token"),
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          setIsAuthenticated(false);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        if (data["authenticated"] === true) {
          setIsAuthenticated(true);
          setEmail(data["user_email"]);
          setPhone(data["user_phone"]);
          setPan(data["user_pan"]);
          setCash(data["user_cash"]);
          setWalr(data["user_walr"]);
          setResidex(data["user_residex"]);
          setKycStatus(data["total_kyc"]);
          setLoaderCheckAuth(false);
          const userEmail = data["user_email"];
          if (userEmail in admin) {
            setLabel("Admin");
            setDesignation(admin[userEmail]);
          } else if (userEmail in agent) {
            setLabel("Agent");
            setDesignation(agent[userEmail]);
          } else if (userEmail in employee) {
            setLabel("Employee");
            setDesignation(employee[userEmail]);
          } else {
            setLabel("Client");
            setDesignation("N/A");
          }
        } else {
          setIsAuthenticated(false);
          setLoaderCheckAuth(false);
        }
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  }, [
    isAuthenticated,
    email,
    phone,
    pan,
    walr,
    residex,
    kycStatus,
    label,
    designation,
  ]);

  return {
    loaderCheckAuth,
    isAuthenticated,
    email,
    phone,
    pan,
    cash,
    walr,
    residex,
    kycStatus,
    label,
    designation,
  };
};

export default CheckAuth;
