import React, { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import CheckAuth from "../components/ServiceFunctions/CheckAuth";

function SchedulePopup({ onReload, onClose, date }) {
  let eventFormData = JSON.parse(secureLocalStorage.getItem("eventFormData"));
  const { email, phone } = CheckAuth();
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState(
    eventFormData !== null
      ? eventFormData
      : {
          title: "",
          date: date.toISOString().slice(0, 10),
          deputed: "",
          description: "",
        }
  );

  const validateForm = () => {
    const { title, date, deputed, description } = formData;
    if (!title || !date || !deputed || !description) {
      alert("All fields are required.");
      return false;
    }
    return true;
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    secureLocalStorage.setItem("eventFormData", JSON.stringify(formData));
    eventFormData = JSON.parse(secureLocalStorage.getItem("eventFormData"));
  };

  const handleSave = async () => {
    if (validateForm()) {
      setLoader(true);
      fetch(`https://api-scdndestates.com/api/set-event-and-reminders/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          phone,
          formData,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to save event/reminder");
          }
          return response.json();
        })
        .then((response) => {
          if (response.message === "Success") {
            secureLocalStorage.setItem(
              "admin-calender",
              JSON.stringify(response.dates)
            );
            onReload(response.dates);
          }
          onClose();
          setFormData({
            title: "",
            date: new Date().toISOString().slice(0, 10),
            deputed: "",
            description: "",
          });
          secureLocalStorage.removeItem("eventFormData");
          eventFormData = JSON.parse(
            secureLocalStorage.getItem("eventFormData")
          );
          setLoader(false);
        })
        .catch((error) => {
          // console.error("Error during logout:", error);
        });
    }
  };

  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 ">
        <div className="bg-white  rounded-lg w-[90%] xl:w-[500px] h-[700px] xl:h-[55%] max-w-md pop-up-animation">
          <div className="flex items-center justify-between px-4 py-2 border-b-2 ">
            <div className="text-black font-semibold text-lg">Add Events</div>
            <div onClick={onClose} className=" flex justify-end  rounded-full">
              <button
                className="circleadmin bg-white"
                data-animation="showShadow"
                data-remove="3000"
              ></button>
            </div>
          </div>

          <div className="flex items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base mt-2">
            <img className="w-8 h-8" src="/images/title-admin.png" alt="" />
            <input
              type="text"
              value={formData.title}
              className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Add Title"
              name="title"
              onChange={handleInputChange}
            />
          </div>

          <div className="flex items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base">
            <img className="w-8 h-8 " src="/images/calen-admin.png" alt="" />
            <input
              type="date"
              value={formData.date}
              className="border rounded-lg px-3 py-1.5 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
              name="date"
              onChange={handleInputChange}
            />
          </div>

          <div className="flex items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base">
            <img className="w-8 h-8" src="/images/assign-admin.png" alt="" />
            <input
              type="text"
              value={formData.deputed}
              className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Assigned to"
              name="deputed"
              onChange={handleInputChange}
            />
          </div>

          <div className="flex items-start px-4 py-2 gap-2 text-[#212121] font-medium text-base">
            <img className="w-8 h-8" src="/images/desc-admin.png" alt="" />

            <textarea
              type="text"
              value={formData.description}
              className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400 min-h-[150px] text-left align-text-top"
              placeholder="Describe here..."
              name="description"
              onChange={handleInputChange}
            />
          </div>

          <div className="flex justify-end px-4 py-2 mb-2">
            {!loader ? (
              <button
                className="button-excelsmall w-[80px] py-1.5 animationIcon-shine-blue"
                onClick={() => {
                  handleSave();
                }}
              >
                <i className="text-base md:text-lg">
                  <span>
                    <img
                      src="/images/post.svg"
                      className="icon w-3 h-3 fill-white"
                      alt=""
                    />
                  </span>
                </i>
                <p className="font-semibold">Post</p>
              </button>
            ) : (
              <button className="button-excelsmall w-[80px] py-1.5 animationIcon-shine-blue">
                <img src="/images/btn-transparent.gif" className="h-6" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchedulePopup;
