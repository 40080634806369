import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Loader from "../../components/Loaders/Loader2";

function WalrChart() {
  const [seriesDataYes, setSeriesDataYes] = useState([]);
  const [series, setSeries] = useState([]);
  const [seriesDataNo, setSeriesDataNo] = useState([]);
  const [category, setCategory] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [loader, setLoader] = useState(true);
  const [tableContent, setTableContent] = useState(null);

  useEffect(() => {
    fetch(`https://api-scdndestates.com/api/client-liabilities-data/`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setSeries(data.data);
        const seriesDataYes1 = [];
        const seriesDataNo1 = [];
        const categories = series.map((item, index) => Object.keys(item));
        categories.forEach((quarter) => {
          const val = data.data2?.WALR[quarter];
          if (val) {
            seriesDataYes1.push(val?.CoverYes * 1000 || 0);
            seriesDataNo1.push(val?.CoverNo * 1000 || 0);
          } else {
            seriesDataYes1.push(0);
            seriesDataNo1.push(0);
          }
          setLoader(false);
        });
        setCategory(categories);
        setSeriesDataYes(seriesDataYes1);
        setSeriesDataNo(seriesDataNo1);
        setDataFetched(true);
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  }, [dataFetched]);

  const formatRupees = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    })
      .format(value)
      .replace("₹", "");
  };

  function formattedDate(val) {
    const date = new Date(val);
    const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-IN", options);
    return formattedDate;
  }

  function getMaturityDate(series, productName) {
    const p = productName;
    const productData = series.find((item) => Object.keys(item)[0] === p);
    return productData ? formattedDate(productData[p]["Maturity Date"]) : "";
  }

  function getCouponDate(series, productName, cover) {
    const p = productName;
    const productData = series.find((item) => Object.keys(item)[0] === p);
    var couponpostReset = 0;
    if (cover === "Cover Yes") {
      couponpostReset = productData ? productData[p]["Coupon post reset"] : "";
    } else {
      const lastSeriesItem = series[series.length - 1];
      const lastSeriesKey = Object.keys(lastSeriesItem)[0];
      couponpostReset = lastSeriesItem[lastSeriesKey]["Coupon at Issue"];
    }
    couponpostReset = parseInt(couponpostReset);
    return couponpostReset;
  }

  function getCouponIssue(series, productName) {
    const p = productName;
    const productData = series.find((item) => Object.keys(item)[0] === p);
    const couponIssue = productData ? productData[p]["Coupon at Issue"] : "";
    return couponIssue;
  }

  function getOptionPrice(series, productName, cover) {
    const p = productName;
    const productData = series.find((item) => Object.keys(item)[0] === p);
    var optionPrice = productData ? productData[p]["Option Price"] : "";
    if (cover === "Cover Yes") return optionPrice;
    else {
      optionPrice = 0;
    }
    return optionPrice;
  }

  useEffect(() => {
    const defaultDataPointIndex = 0;
    const defaultCitiesIndex = 0;
    handleBarClick(null, null, {
      dataPointIndex: defaultDataPointIndex,
      seriesIndex: defaultCitiesIndex,
    });
  }, []);

  const handleBarClick = (
    event,
    chartContext,
    { dataPointIndex, seriesIndex }
  ) => {
    const seriesName = chartData.options.xaxis.categories[dataPointIndex]?.[0];
    const category = chartData.series[seriesIndex].name;
    const value = chartData.series[seriesIndex].data[dataPointIndex];
    setTableContent(
      <>
        {/* Heading */}
        <div className="max-2xl:flex-col 2xl:flex-row flex items-center justify-between flex-wrap max-2xl:gap-y-2 p-2">
          <div className="flex items-center">
            <h5 className="font-bold text-xs">
              WALR @RBI:{" "}
              <span>
                <a
                  className="text-[#008FFB] text-xs"
                  href="https://website.rbi.org.in/en/web/rbi/-/press-releases/lending-and-deposit-rates-of-scheduled-commercial-banks-november-2024"
                  target="blank"
                >
                  website.rbi.org.in
                </a>
              </span>
            </h5>
          </div>
          <div className="flex items-center gap-2">
            <div className="font-bold text-xs">
              Quantity:{" "}
              <span className="font-bold border-[1px] px-1 py-1 text-xs  text-[#008FFB] rounded-md">
                {isNaN(value / 1000) ? 0 : value / 1000} units
              </span>
            </div>
          </div>
        </div>
        <hr className=" mb-2" />

        {/* WALR Data */}
        <div className="flex flex-col justify-between">
          <div className="lg:flex items-end justify-between">
            <div className="col-lg-6 col-md-12 col-sm-12 flex lg:flex-col text-sm xl:text-base justify-between items-center px-2 mb-2 lg:mb-0">
              <h6 className="fw-bold text-center text-sm">
                Next Payment <br />
                <span className="font-size1">( ₹ )</span>
              </h6>
              <div className="border-4 border-[#4c3fbb] m-2 d-flex align-items-center justify-content-center info-style rounded-circle">
                <h3 className="text-[#4c3fbb] fw-bold text-xl">
                  {isNaN(
                    (getCouponDate(series, seriesName, category) * value) / 400
                  )
                    ? "0"
                    : parseInt(
                        (getCouponDate(series, seriesName, category) * value) /
                          400
                      ).toLocaleString("en-IN")}
                </h3>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 flex lg:flex-col text-sm xl:text-base justify-between items-center px-2 mb-2 lg:mb-0">
              <h6 className="fw-bold text-center text-sm">
                Coupon at Issue <br />
                <span className="font-size1">( % )</span>
              </h6>
              <div className="border-4 border-[#635DAD] m-2 d-flex align-items-center justify-content-center info-style rounded-circle">
                <h3 className="text-[#655DAD] fw-bold text-xl">
                  {getCouponIssue(series, seriesName) === ""
                    ? 0
                    : parseFloat(getCouponIssue(series, seriesName)).toFixed(2)}
                </h3>
              </div>
            </div>
          </div>

          <div className="lg:flex items-end justify-between">
            <div className="col-lg-6 col-md-12 col-sm-12 flex lg:flex-col text-sm xl:text-base justify-between items-center px-2 mb-2 lg:mb-0">
              <h6 className="fw-bold text-center text-sm">
                Maturity Date <br />{" "}
                <span className="text-[13px] font-semibold">dd/mm/yy</span>
              </h6>
              <div className="border-4 border-[#20656b] m-2 d-flex align-items-center justify-content-center info-style rounded-circle">
                <h6 className="text-[#20656b] fw-bold text-lg md:text-xl text-center">
                  {getMaturityDate(series, seriesName) === ""
                    ? 0
                    : getMaturityDate(series, seriesName)}
                </h6>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 flex lg:flex-col text-sm xl:text-base justify-between items-center px-2 mb-2 lg:mb-0">
              <h6 className="fw-bold text-center text-sm">
                Option Price
                <br />
                <span className="font-size1">( ₹ )</span>
              </h6>
              <div className="border-4 border-[#0D828D] m-2 d-flex align-items-center justify-content-center info-style rounded-circle">
                <h3 className="text-[#0D828D] fw-bold text-xl">
                  {getOptionPrice(series, seriesName, category) === ""
                    ? 0
                    : parseFloat(
                        getOptionPrice(series, seriesName, category)
                      ).toFixed(2)}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const chartData = {
    series: [
      { name: "Cover Yes", data: seriesDataYes, color: "#3B71CA" },
      { name: "Cover No", data: seriesDataNo, color: "#8d80ec" },
    ],
    options: {
      chart: {
        type: "bar",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            handleBarClick(event, chartContext, config);
          },
        },
        stacked: true,
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      fill: {
        type: ["gradient", "pattern"],
        gradient: {
          type: "vertical",
          stops: [0, 33, 66, 100],
          colorStops: [
            { offset: 0, color: "#3B71CA" },
            { offset: 33, color: "#0590F8" }, // New color stop
            { offset: 66, color: "#0590F8" },
            { offset: 100, color: "#3B71CA" },
          ],
        },
        pattern: {
          style: "circles",
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          horizontal: false,
          dataLabels: {
            position: "top",
            total: {
              enabled: false,
              style: {
                colors: ["#333"],
              },
              formatter: function (val, opts) {
                const total = chartData.series.reduce(
                  (acc, series) => acc + series.data[opts.dataPointIndex],
                  0
                );
                return "₹" + formatRupees(total);
              },
            },
          },
        },
      },
      dataLabels: {
        position: "top",
        enabled: false,
      },
      xaxis: {
        categories: category,
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
      },
      yaxis: {
        title: { text: "Amount (₹)" },
        labels: {
          formatter: function (value) {
            return formatRupees(value / 1000) + "K";
          },
        }, // Set the maximum value for the y-axis
      },
      legend: {
        show: false,
      },
    },
  };

  return (
    <>
      {loader && <Loader />}
      <div className=" w-full">
        <div className="lg:flex justify-between items-center ">
          <div
            id="bar"
            className="p-1 border-2  overflow-x-scroll rounded-xl w-full lg:w-[78%] mr-3"
          >
            <Chart
              className="min-w-[500px]"
              options={chartData.options}
              series={chartData.series}
              type="bar"
              height="400"
            />
          </div>
          <div className="flex justify-between items-center max-lg:mt-5">
            <div className="py-1 w-full lg:w-[330px] 2xl:w-[400px]  border-2 rounded-xl h-max lg:h-[433px]">
              {tableContent}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WalrChart;
