import React, { useState, useEffect, useRef } from "react";
import PopUp1 from "../../components/PopUp1/PopUp1";
import { useNavigate, useParams } from "react-router-dom";
import FAQ2 from "./FAQ2";
import WalrLineChart from "./WalrLineChart";
import ResidexLineChart from "./ResidexLineChart";
import WalrChart from "./WalrChart";
import ResidexChart from "./ResidexChart.jsx";
import CollateralChart from "./CollateralChart";
import PieChart from "./PieChart.jsx";
import "./Custodian.css";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import UserData from "../../components/ServiceFunctions/UserData";
import Loader2 from "../../components/Loaders/Loader2";
import KycPopUp from "../../components/PopUp1/PopUp2";
import Header from "../../components/Header/Header";
import { FaCoins } from "react-icons/fa";
import { IoArrowUpCircle } from "react-icons/io5";
import { GoKebabHorizontal } from "react-icons/go";
import { IoIosCloseCircle } from "react-icons/io";
import { GiBuyCard, GiReceiveMoney } from "react-icons/gi";
import { FaSellsy } from "react-icons/fa6";
import { MdOutlineDataExploration } from "react-icons/md";
import { PiBookOpenTextBold } from "react-icons/pi";

function Custodian() {
  const { isAuthenticated, kycStatus, email, pan, phone, label } = CheckAuth();
  const { loader } = UserData({
    isAuthenticated,
    email,
    phone,
    pan,
    formName: "",
  });
  const { page } = useParams();
  const navigate = useNavigate();
  const [iconChange, setIconChange] = useState(false);
  const [category, setCategory] = useState("Gold");
  const [category2, setCategory2] = useState("Rupee Bonds");
  const [dataFetched, setDataFetched] = useState(false);
  const [walrGraphData, setWalrGraphData] = useState({});
  const [residexGraphData, setResidexGraphData] = useState({});
  const [residexData, setResidexData] = useState([]);
  const [walrData, setWalrData] = useState([]);
  const [graphXAxis, setGraphXAxis] = useState({});
  const [graphXAxis2, setGraphXAxis2] = useState({});

  useEffect(() => {
    if (label) {
      if (label === "Agent") navigate("/accessDenied");
    }
  }, [label]);

  // WALRPieChart Data
  const pieData = [
    {
      name: "Gold",
      value:
        walrData[0]?.["Gold"]?.["Sovereign"] *
          (walrData[0]?.["Gold"]?.["IBJA 999 rate"] / 10) +
        walrData[0]?.["Gold"]?.["LBMA AM price"] * 0,
      color: "#ffbb00",
    },
    { name: "Cash", value: walrData[2]?.["Cash"]?.["Cash"], color: "#645cb0" },
    {
      name: "Nifty",
      value:
        (walrData[1]?.["Nifty"]?.["SmallCap 250"] *
          walrData[1]?.["Nifty"]?.["SmallCap ETF"]) /
          1000 +
        (walrData[1]?.["Nifty"]?.["Nifty50 Futures"] * 0) / 1000,
      color: "#a8031c",
    },
  ];
  // WALRPieChart Data

  //RESIDEXWALRPieChart Data
  const pieData2 = [
    {
      name: "Cash",
      value: residexData[4]?.["Cash"]?.["Cash"],
      color: "#645cb0",
    },
    {
      name: "US Treasuries",
      value:
        residexData[2]?.["Tax-Free"]?.["amount"] +
        residexData[3]?.["SDL"]?.["amount"],
      color: "#6f705e",
    },
    {
      name: "Rupee Bonds",
      value:
        residexData[0]?.["Taxable"]?.["amount"] +
        residexData[1]?.["Trusts"]?.["amount"],
      color: "#0066cc",
    },
  ];
  //RESIDEXPieChart Data
  //Icons for title
  const getIconForTitle = (name) => {
    switch (name) {
      case "Collateral WALR":
        return <GiBuyCard size={20} />;
      case "Collateral RESIDEX":
        return <GiReceiveMoney size={20} />;
      case "On-chain WALR":
        return <FaSellsy size={20} />;
      case "On-chain RESIDEX":
        return <FaCoins size={20} />;
      case "Coverage Ratio":
        return <MdOutlineDataExploration size={20} />;
      case "Hyper Ledger":
        return <PiBookOpenTextBold size={20} />;
      default:
        return null;
    }
  };
  //Icons for title

  // handle content according to piechart hover
  const handleShowContent = (name) => {
    setCategory(name);
  };

  const handleShowContent2 = (name) => {
    setCategory2(name);
  };
  // handle content according to piechart hover

  // custodian sidebar API
  var custodianApi = [
    {
      id: 1,
      title: "Collateral Values",
      name: "Collateral WALR",
      link: "collateralWALR",
    },
    {
      id: 2,
      title: "Collateral Values",
      name: "Collateral RESIDEX",
      link: "collateralRESIDEX",
    },
    {
      id: 3,
      title: "On-chain DeAs",
      name: "On-chain WALR",
      link: "OnchainWALR",
    },
    {
      id: 4,
      title: "On-chain DeAs",
      name: "On-chain RESIDEX",
      link: "OnchainRESIDEX",
    },

    {
      id: 5,
      title: "Coverage Ratio",
      name: "Coverage Ratio",
      link: "Coverage",
    },
    {
      id: 6,
      title: "Hyper Ledger",
      name: "Hyper Ledger",
      link: "hyperledger",
    },
  ];

  const groupedButtons = custodianApi.reduce((acc, card) => {
    if (!acc[card.title]) {
      acc[card.title] = [];
    }
    acc[card.title].push(card);
    return acc;
  }, {});
  // custodian sidebar API

  useEffect(() => {
    // Read pie chart card data
    fetch(`https://api-scdndestates.com/api/read-custodian-values/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to logout");
        }
        return response.json();
      })
      .then((response) => {
        setWalrData(response.data);
        setResidexData(response.data2);
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
    // lineGraph data
    fetch(`https://api-scdndestates.com/api/get-custodian-data/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setWalrGraphData(data["data"]);
        setResidexGraphData(data["data2"]);
        setGraphXAxis(calculateXaxis(data.from, data.to));
        setGraphXAxis2(calculateXaxis(data.from2, data.to2));
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
    setDataFetched(true);
  }, [dataFetched]);

  function calculateXaxis(fromDate, toDate) {
    const dates = [];
    let currentDate = new Date(fromDate);
    const endDate = new Date(toDate);

    while (currentDate <= endDate) {
      const month = currentDate.toLocaleString("default", { month: "short" });
      const year = currentDate.toLocaleString("default", { year: "2-digit" });
      const day = currentDate.getDate();
      dates.push(`${day}-${month}-${year}`);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  const containerRef = useRef(null);

  // Close the div when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIconChange(false); // Close the div
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIconChange]);

  return (
    <div>
      <Header />
      {loader ? (
        <Loader2 />
      ) : !isAuthenticated ? (
        <PopUp1 />
      ) : kycStatus < 85 ? (
        <KycPopUp />
      ) : (
        <>
          <div className="main h-full after-header max-w-[1800px] mx-auto ">
            <div className="w-[90%] mx-auto flex flex-row lg:gap-3 justify-start">
              {/* Left Section */}
              <div className="lg:w-auto px-0 py-2 flex flex-col  ">
                {/* SideBar */}

                <div className="min-w-[250px] border border-[#D8E5F9] rounded-xl px-3 py-2 hidden-768 pb-3 z-0">
                  <div className="custodian-buttons">
                    {Object.entries(groupedButtons).map(
                      ([title, cards], index) => (
                        <div key={index} className="flex flex-col gap-2">
                          <div
                            key={index}
                            className="side-sub-title flex justify-center"
                          ></div>
                          {cards.map((card) => (
                            <div
                              key={index}
                              onClick={() =>
                                navigate(`/custodianAC/${card.link}`)
                              }
                            >
                              <button
                                key={index}
                                className={`custodian-button bg-gray-100 border flex gap-2 items-center py-2.5 px-3 ${
                                  page === card.link ? "cust-active" : ""
                                }`}
                                onClick={() =>
                                  navigate(`/custodianAC/${card.link}`)
                                }
                              >
                                {getIconForTitle(card.name)}&nbsp;
                                {card.name}
                              </button>
                            </div>
                          ))}
                        </div>
                      )
                    )}
                  </div>
                </div>
                {/* SideBar */}

                {/* SideBar-sm */}

                <div className="fixed bottom-0 bg-[#3B71CA] p-2.5 left-0 md:px-20 w-full inline-flex text-[10px] justify-between items-end z-10 font-bold lg:hidden">
                  {Object.entries(groupedButtons).map(
                    ([title, cards], index) => (
                      <>
                        {title !== "On-chain DeAs" ? (
                          <>
                            {cards.map((card) => (
                              <>
                                <div
                                  key={index}
                                  className="flex flex-col justify-center items-center w-[60px] h-[60px] gap-1 text-[#D8E5F9] hover:text-white"
                                  onClick={() =>
                                    navigate(`/custodianAC/${card.link}`)
                                  }
                                >
                                  <span
                                    className={`bg-[#3b71ca] rounded-full h-fit flex flex-col items-center p-1.5 ${
                                      page === card.link && iconChange === false
                                        ? "nav-active"
                                        : "top-0 pb-2.5"
                                    }`}
                                  >
                                    <span className="text-[10px] p-1 rounded-full top-0 text-center ">
                                      {getIconForTitle(card.name)}
                                    </span>
                                    <p className="text-[10px] text-center leading-3">
                                      {card.name.split(" ")[0]} <br />
                                      {card.name.split(" ")[1]}
                                    </p>
                                  </span>
                                </div>
                              </>
                            ))}
                          </>
                        ) : (
                          <>
                            <>
                              <div
                                className="flex flex-col justify-center items-center text-black w-[60px] h-[60px] bg-[#D8E5F9] rounded-2xl border-[#386BC0] border-2 duration-200"
                                onClick={() => {
                                  // handleSelectCard("Open");
                                  setIconChange(!iconChange);
                                }}
                              >
                                {!iconChange ? (
                                  <GoKebabHorizontal className="font-bold text-lg" />
                                ) : (
                                  <IoArrowUpCircle className="font-bold text-lg" />
                                )}
                                {/* <GoKebabHorizontal className="font-bold text-xl" /> */}
                                <p className="text-center text-[10px]">
                                  On-chain DeAs
                                </p>
                              </div>
                              {/*  */}
                            </>
                          </>
                        )}
                      </>
                    )
                  )}
                </div>

                {iconChange && (
                  <div className="demo5-container  z-0 ">
                    <div
                      ref={containerRef}
                      className={`p-4 flex flex-col absolute bottom-[69px]  z-0 w-[250px] ${
                        !iconChange ? "" : "slide-up-animation"
                      }`}
                    >
                      {Object.entries(groupedButtons).map(
                        ([title, cards], index) => (
                          <>
                            {title === "On-chain DeAs" && (
                              <>
                                <div className="flex flex-col items-center justify-center text-xs gap-2 font-bold  w-full">
                                  {cards.map((card) => (
                                    <div
                                      className="flex  justify-center items-center border-[1px] border-blue-200 bg-white w-full pr-3  rounded-full shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                                      onClick={() => {
                                        navigate(`/custodianAC/${card.link}`);
                                        setIconChange(false);
                                      }}
                                    >
                                      <i className="bg-blue-200 p-2 rounded-full fill-black">
                                        {getIconForTitle(card.name)}
                                      </i>
                                      <p className="text-center flex w-full ml-2">
                                        {card.name}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </>
                            )}
                          </>
                        )
                      )}
                    </div>
                  </div>
                )}
                {/* SideBar-sm */}
              </div>

              {/* Right Section */}
              <div className="w-full py-2 min-h-96">
                {page === "collateralWALR" ? (
                  <div className="w-full flex-col justify-center items-center">
                    <div className="w-full flex justify-start items-start">
                      <div className="w-full flex flex-wrap justify-start gap-y-3 gap-3">
                        <div className="w-full md:w-[335px] lg:w-[320px] xl:w-[420px] h-[250px]  pt-1 overflow-hidden flex  justify-center border-2 rounded-xl">
                          <PieChart
                            data={pieData}
                            showContent={handleShowContent}
                          />
                        </div>
                        <div className="w-full md:w-[335px] overflow-x-scroll lg:w-[320px] xl:w-[420px] h-[250px] lg:pl-20 xl:pl-0  flex justify-center border-2 rounded-xl">
                          {category === "Nifty" && (
                            <WalrLineChart
                              category="Nifty"
                              graphData={walrGraphData?.[0]?.["SmallCap 250"]}
                              graphXAxis={graphXAxis}
                            />
                          )}
                          {category === "Gold" && (
                            <WalrLineChart
                              category="Gold"
                              graphData={walrGraphData?.[1]?.["LBMA AM price"]}
                              graphXAxis={graphXAxis}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {/* PieChart Content */}
                    <div className="apexChart-bottom flex flex-wrap items-center justify-start mt-3 gap-y-3 gap-3">
                      <div
                        className={`${
                          category === "Nifty"
                            ? "bg-[#ff9ead]"
                            : category === "Gold"
                            ? "bg-[#fff4b2]"
                            : "bg-[#d7d2ff]"
                        } dataCard w-full md:w-[335px] lg:w-[320px] xl:w-[420px] p-2`}
                      >
                        <div className="flex justify-between items-center h-full gap-x-2">
                          <div className="animationIcon Icon flex justify-center items-center w-1/5 h-full object-cover p-1">
                            {/* 1st card */}
                            {category === "Nifty" ? (
                              <img
                                className="w-[90%] h-[90%]"
                                src="/images/bull-market.png"
                                alt=""
                              />
                            ) : category === "Gold" ? (
                              <img
                                className="w-[90%] h-[90%]"
                                src="/images/gold-bar.png"
                                alt=""
                              />
                            ) : (
                              <img
                                className="w-[90%] h-[90%]"
                                src="/images/money.png"
                                alt=""
                              />
                            )}
                          </div>
                          <div className="w-4/5 h-full flex-col items-center gap-y-2">
                            <div className="h-1/2 w-full flex items-center  gap-x-2 ">
                              <div className="animationIcon Name font-semibold h-full text-xs 2xl:text-sm w-full max-w-[60%]  flex justify-center align-center overflow-hidden px-1 ">
                                {category === "Nifty"
                                  ? "Smallcap 250 ETF"
                                  : category === "Gold"
                                  ? "Sovereign Gold Bonds"
                                  : "Bank of Baroda"}
                              </div>
                              <div className="animationIcon Name font-bold h-full text-xs 2xl:text-sm  w-full max-w-[40%]  flex justify-center align-center px-1 overflow-hidden ">
                                {category === "Nifty"
                                  ? walrData[1]?.["Nifty"]?.[
                                      "SmallCap ETF"
                                    ].toLocaleString("en-IN") + " Units"
                                  : category === "Gold"
                                  ? walrData[0]?.["Gold"]?.[
                                      "Sovereign"
                                    ].toLocaleString("en-IN") + " gm"
                                  : "BARBOPAPTIPU"}
                              </div>
                            </div>
                            <div className="h-1/2  w-full flex items-center gap-x-2 ">
                              <div className="animationIcon Name font-semibold h-full text-xs 2xl:text-sm w-full max-w-[60%]  flex justify-center align-center overflow-hidden px-1 ">
                                {category === "Nifty"
                                  ? "Index Spot Price"
                                  : category === "Gold"
                                  ? "IBJA 999 rate"
                                  : "Outstanding Balance"}
                              </div>
                              <div className="animationIcon Name font-bold h-full text-xs 2xl:text-sm w-full max-w-[40%]  flex justify-center align-center px-1 overflow-hidden ">
                                {category === "Nifty"
                                  ? "₹ " +
                                    walrData[1]?.["Nifty"]?.[
                                      "SmallCap 250"
                                    ].toLocaleString("en-IN")
                                  : category === "Gold"
                                  ? "₹ " +
                                    walrData[0]?.["Gold"]?.[
                                      "IBJA 999 rate"
                                    ].toLocaleString("en-IN")
                                  : "₹ " +
                                    walrData[2]?.["Cash"]?.[
                                      "Cash"
                                    ].toLocaleString("en-IN")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {category !== "Cash" && (
                        <div
                          className={`${
                            category === "Nifty"
                              ? "bg-[#ff9ead]"
                              : category === "Gold" && "bg-[#fff4b2]"
                          } dataCard w-full md:w-[335px] lg:w-[320px] xl:w-[420px] p-2`}
                        >
                          <div className="flex justify-between items-center h-full gap-x-2 ">
                            <div className="animationIcon Icon flex justify-center items-center w-1/5 h-full object-cover p-1">
                              {/* 2nd card */}
                              {category === "Nifty" ? (
                                <img
                                  className="w-[90%] h-[90%]"
                                  src="/images/Nifty50-icon.png"
                                  alt=""
                                />
                              ) : (
                                category === "Gold" && (
                                  <img
                                    className="w-[90%] h-[90%]"
                                    src="/images/gold-cart.png"
                                    alt=""
                                  />
                                )
                              )}
                            </div>
                            <div className="w-4/5 h-full flex-col items-center gap-y-2 ">
                              <div className="h-1/2  w-full flex items-center  gap-x-2">
                                <div className="animationIcon Name font-semibold h-full text-xs 2xl:text-sm w-full max-w-[60%]  flex justify-center align-center overflow-hidden px-1 ">
                                  {category === "Nifty"
                                    ? "Nifty 50 Futures"
                                    : category === "Gold" &&
                                      "GoldPetal Futures"}
                                </div>
                                <div className="animationIcon Name font-bold h-full text-xs 2xl:text-sm  w-full max-w-[40%]  flex justify-center align-center px-1 overflow-hidden  ">
                                  {category === "Nifty"
                                    ? "₹ " + 0
                                    : category === "Gold" && "₹ " + 0}
                                </div>
                              </div>
                              <div className="h-1/2  w-full flex items-center  gap-x-2">
                                <div className="animationIcon Name font-semibold h-full text-xs 2xl:text-sm w-full max-w-[60%]  flex justify-center align-center overflow-hidden px-1 ">
                                  {category === "Nifty"
                                    ? "Futures Premium"
                                    : category === "Gold" && "LBMA Gold AM"}
                                </div>
                                <div className="animationIcon Name font-bold h-full text-xs 2xl:text-sm w-full max-w-[40%]  flex justify-center align-center px-1 overflow-hidden ">
                                  {category === "Nifty"
                                    ? "₹ " +
                                      walrData[1]?.["Nifty"]?.[
                                        "Nifty50 Futures"
                                      ]?.toLocaleString("en-IN")
                                    : category === "Gold" &&
                                      "₹ " +
                                        walrData[0]?.["Gold"]?.[
                                          "LBMA AM price"
                                        ]?.toLocaleString("en-IN")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* PieChart Content */}
                  </div>
                ) : page === "collateralRESIDEX" ? (
                  <div className="w-full flex-col justify-center items-center">
                    <div className="w-full flex justify-between items-start">
                      <div className="w-full flex flex-wrap justify-start gap-y-3 gap-3">
                        <div className="w-full md:w-[335px] lg:w-[320px] xl:w-[420px] h-[250px]  pt-1 overflow-hidden flex  justify-center border-2 rounded-xl">
                          <PieChart
                            data={pieData2}
                            showContent={handleShowContent2}
                          />
                        </div>
                        <div className="w-full md:w-[335px] lg:w-[320px] xl:w-[420px] h-[250px]  lg:pl-20 xl:pl-0 overflow-x-scroll overflow-y-hidden flex justify-center  border-2 rounded-xl ">
                          {category2 === "US Treasuries" && (
                            <ResidexLineChart
                              category="US Treasuries"
                              graphData={
                                residexGraphData?.[0]?.["US 10Y constant"]
                              }
                              graphXAxis={graphXAxis2}
                            />
                          )}
                          {category2 === "Rupee Bonds" && (
                            <ResidexLineChart
                              category="Rupee Bonds"
                              graphData={
                                residexGraphData?.[1]?.["MIBOR 3 months"]
                              }
                              graphXAxis={graphXAxis2}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {/* PieChart Content */}
                    <div className="apexChart-bottom flex flex-wrap items-center justify-start mt-3 gap-y-3 gap-3">
                      {category2 !== "Cash" && (
                        <>
                          <div
                            className={`${
                              category2 === "US Treasuries"
                                ? "bg-[#c6c7b9]"
                                : category2 === "Rupee Bonds" && "bg-[#abd6ff]"
                            } dataCard2 w-full md:w-[335px] lg:w-[320px] xl:w-[420px] p-2`}
                          >
                            <div className=" flex justify-between items-center h-full gap-x-2">
                              <div className="animationIcon Icon flex justify-center items-center w-1/5 h-full object-cover p-1">
                                {/* 1st card */}
                                {category2 === "US Treasuries" ? (
                                  <img
                                    className="w-[90%] h-[90%]"
                                    src="/images/factory.png"
                                    alt=""
                                  />
                                ) : (
                                  category2 === "Rupee Bonds" && (
                                    <img
                                      className="w-[90%] h-[90%]"
                                      src="/images/rupee.png"
                                      alt=""
                                    />
                                  )
                                )}
                              </div>
                              <div className="w-4/5 h-full flex-col items-center gap-y-1">
                                <div className="h-1/2  w-full flex items-center  gap-x-2 ">
                                  <div className="animationIcon Name font-semibold py-1 text-xs  w-full max-w-[60%]  flex justify-center align-center overflow-hidden px-1 ">
                                    {category2 === "US Treasuries"
                                      ? "Tax Free Bonds"
                                      : category2 === "Rupee Bonds"
                                      ? "Rated Corporate"
                                      : ""}
                                  </div>
                                  <div className="animationIcon Name font-semibold py-1 text-xs   w-full max-w-[40%]  flex justify-center align-center px-1 overflow-hidden ">
                                    {category2 === "US Treasuries"
                                      ? "₹ " +
                                        residexData[2]?.["Tax-Free"]?.[
                                          "amount"
                                        ].toLocaleString("en-IN")
                                      : category2 === "Rupee Bonds"
                                      ? "₹ " +
                                        residexData[0]?.["Taxable"]?.[
                                          "amount"
                                        ].toLocaleString("en-IN")
                                      : ""}
                                  </div>
                                </div>
                                <div className="h-1/2  w-full flex items-center  gap-x-2 ">
                                  <div className="animationIcon Name font-semibold py-1 text-xs  w-full max-w-[60%]  flex justify-center align-center overflow-hidden px-1 ">
                                    {(category2 === "US Treasuries" ||
                                      category2 === "Rupee Bonds") &&
                                      "Coupon Rate"}
                                  </div>
                                  <div className="animationIcon Name font-semibold py-1 text-xs  w-full max-w-[40%]  flex justify-center align-center px-1 overflow-hidden  ">
                                    {category2 === "US Treasuries"
                                      ? residexData[2]?.["Tax-Free"]?.[
                                          "rate"
                                        ].toFixed(2) + " %"
                                      : category2 === "Rupee Bonds"
                                      ? residexData[0]?.["Taxable"]?.[
                                          "rate"
                                        ].toFixed(2) + " %"
                                      : ""}
                                  </div>
                                </div>
                                <div className="h-1/2  w-full flex items-center  gap-x-2 ">
                                  <div className="animationIcon Name font-semibold py-1 text-xs  w-full max-w-[60%]  flex justify-center align-center overflow-hidden px-1 ">
                                    {category2 === "US Treasuries"
                                      ? "Duration"
                                      : category2 === "Rupee Bonds"
                                      ? "Credit Spread"
                                      : ""}
                                  </div>
                                  <div className="animationIcon Name font-semibold py-1 text-xs  w-full max-w-[40%]  flex justify-center align-center px-1 overflow-hidden ">
                                    {category2 === "US Treasuries"
                                      ? residexData[2]?.["Tax-Free"]?.[
                                          "dur"
                                        ].toFixed(2) + " Years"
                                      : category2 === "Rupee Bonds"
                                      ? residexData[0]?.["Taxable"]?.[
                                          "dur"
                                        ].toFixed(2) + " Years"
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${
                              category2 === "US Treasuries"
                                ? "bg-[#c6c7b9]"
                                : category2 === "Rupee Bonds" && "bg-[#abd6ff]"
                            } dataCard2 w-full md:w-[335px] lg:w-[320px] xl:w-[420px] p-2`}
                          >
                            <div className=" flex justify-between items-center h-full gap-x-2">
                              <div className="animationIcon Icon flex justify-center items-center w-1/5 h-full object-cover p-1">
                                {/* 2nd card */}
                                {category2 === "US Treasuries" ? (
                                  <img
                                    className="w-[90%] h-[90%]"
                                    src="/images/infrastructure.png"
                                    alt=""
                                  />
                                ) : (
                                  category2 === "Rupee Bonds" && (
                                    <img
                                      className="w-[90%] h-[90%]"
                                      src="/images/government.png"
                                      alt=""
                                    />
                                  )
                                )}
                              </div>
                              <div className="w-4/5 h-full flex-col items-center gap-y-1">
                                <div className="h-1/2  w-full flex items-center  gap-x-2 ">
                                  <div className="animationIcon Name font-semibold py-1 text-xs  w-full max-w-[60%]  flex justify-center align-center overflow-hidden px-1 ">
                                    {category2 === "US Treasuries"
                                      ? "G-sec & SDL"
                                      : category2 === "Rupee Bonds" &&
                                        "Trusts & Green"}
                                  </div>
                                  <div className="animationIcon Name font-semibold py-1 text-xs   w-full max-w-[40%]  flex justify-center align-center px-1 overflow-hidden ">
                                    {category2 === "US Treasuries"
                                      ? "₹ " +
                                        residexData[3]?.["SDL"]?.[
                                          "amount"
                                        ].toLocaleString("en-IN")
                                      : category2 === "Rupee Bonds" &&
                                        "₹ " +
                                          residexData[1]?.["Trusts"]?.[
                                            "amount"
                                          ].toLocaleString("en-IN")}
                                  </div>
                                </div>
                                <div className="h-1/2  w-full flex items-center  gap-x-2 ">
                                  <div className="animationIcon Name font-semibold py-1 text-xs  w-full max-w-[60%]  flex justify-center align-center overflow-hidden px-1 ">
                                    {(category2 === "US Treasuries" ||
                                      category2 === "Rupee Bonds") &&
                                      "Coupon Rate"}
                                  </div>
                                  <div className="animationIcon Name font-semibold py-1 text-xs  w-full max-w-[40%]  flex justify-center align-center px-1 overflow-hidden  ">
                                    {category2 === "US Treasuries"
                                      ? residexData[3]?.["SDL"]?.[
                                          "rate"
                                        ].toFixed(2) + " %"
                                      : category2 === "Rupee Bonds" &&
                                        residexData[1]?.["Trusts"]?.[
                                          "rate"
                                        ].toFixed(2) + " %"}
                                  </div>
                                </div>
                                <div className="h-1/2  w-full flex items-center  gap-x-2 ">
                                  <div className="animationIcon Name font-semibold py-1 text-xs  w-full max-w-[60%]  flex justify-center align-center overflow-hidden px-1 ">
                                    {category2 === "US Treasuries"
                                      ? "Duration"
                                      : category2 === "Rupee Bonds" &&
                                        "Credit Spread"}
                                  </div>
                                  <div className="animationIcon Name font-semibold py-1 text-xs  w-full max-w-[40%]  flex justify-center align-center px-1 overflow-hidden ">
                                    {category2 === "US Treasuries"
                                      ? residexData[3]?.["SDL"]?.[
                                          "dur"
                                        ].toFixed(2) + " Years"
                                      : category2 === "Rupee Bonds" &&
                                        residexData[1]?.["Trusts"]?.[
                                          "dur"
                                        ].toFixed(2) + " Years"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {category2 === "US Treasuries" && (
                        <div
                          className={`${
                            category2 === "US Treasuries"
                              ? "bg-[#c6c7b9]"
                              : category2 === "Rupee Bonds"
                              ? "bg-[#abd6ff]"
                              : "bg-[#d7d2ff]"
                          } dataCard2 w-full md:w-[335px] lg:w-[320px] xl:w-[420px] p-2`}
                        >
                          <div className=" flex justify-between items-center h-full gap-x-2">
                            <div className="animationIcon Icon flex justify-center items-center w-1/5 h-full object-cover p-1">
                              <img
                                className=""
                                src="/images/currency.png"
                                alt=""
                              />
                            </div>
                            <div className="w-4/5 h-full flex-col items-center gap-y-1">
                              <div className="h-1/2  w-full flex items-center  gap-x-2 ">
                                <div className="animationIcon Name font-semibold py-1 text-xs  w-full max-w-[20%]  flex justify-center align-center overflow-hidden px-1 ">
                                  USD
                                </div>
                                <div className="animationIcon Name font-semibold py-1 text-xs   w-full max-w-[50%]  flex justify-center align-center px-1 overflow-hidden ">
                                  2,50,000
                                </div>
                                <div className="animationIcon Name font-semibold py-1 text-xs   w-full max-w-[30%]  flex justify-center align-center px-1 overflow-hidden ">
                                  {"₹ " +
                                    residexData[5]?.["Money"]?.["USD"].toFixed(
                                      2
                                    )}
                                </div>
                              </div>
                              <div className="h-1/2  w-full flex items-center  gap-x-2 ">
                                <div className="animationIcon Name font-semibold py-1 text-xs  w-full max-w-[20%]  flex justify-center align-center overflow-hidden px-1 ">
                                  EUR
                                </div>
                                <div className="animationIcon Name font-semibold py-1 text-xs   w-full max-w-[50%]  flex justify-center align-center px-1 overflow-hidden ">
                                  1,50,000
                                </div>
                                <div className="animationIcon Name font-semibold py-1 text-xs   w-full max-w-[30%]  flex justify-center align-center px-1 overflow-hidden ">
                                  {"₹ " +
                                    residexData[5]?.["Money"]?.["EUR"].toFixed(
                                      2
                                    )}
                                </div>
                              </div>
                              <div className="h-1/2  w-full flex items-center  gap-x-2 ">
                                <div className="animationIcon Name font-semibold py-1 text-xs  w-full max-w-[20%]  flex justify-center align-center overflow-hidden px-1 ">
                                  JPY
                                </div>
                                <div className="animationIcon Name font-semibold py-1 text-xs   w-full max-w-[50%]  flex justify-center align-center px-1 overflow-hidden ">
                                  1,00,000
                                </div>
                                <div className="animationIcon Name font-semibold py-1 text-xs   w-full max-w-[30%]  flex justify-center align-center px-1 overflow-hidden ">
                                  {"₹ " +
                                    residexData[5]?.["Money"]?.["JPY"].toFixed(
                                      2
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {category2 === "Cash" && (
                        <div
                          className={`bg-[#d7d2ff] dataCard w-full max-w-[360px] lg:w-[420px] lg:max-w-[420px] p-2`}
                        >
                          <div className="flex justify-between items-center h-full gap-x-2">
                            <div className="animationIcon Icon flex justify-center items-center w-1/5 h-full object-cover p-1">
                              <img
                                className="w-[90%] h-[90%]"
                                src="/images/money.png"
                                alt=""
                              />
                            </div>
                            <div className="w-4/5 h-full flex-col items-center gap-y-2">
                              <div className="h-1/2  w-full flex items-center  gap-x-2 ">
                                <div className="animationIcon Name font-semibold h-full text-xs 2xl:text-sm w-full max-w-[60%]  flex justify-center align-center overflow-hidden px-1 ">
                                  ICICI Bank
                                </div>
                                <div className="animationIcon Name font-bold h-full text-xs 2xl:text-sm  w-full max-w-[40%]  flex justify-center align-center px-1 overflow-hidden ">
                                  ICIC0000355
                                </div>
                              </div>
                              <div className="h-1/2  w-full flex items-center  gap-x-2 ">
                                <div className="animationIcon Name font-semibold h-full text-xs 2xl:text-sm w-full max-w-[60%]  flex justify-center align-center overflow-hidden px-1 ">
                                  Outstanding Balance
                                </div>
                                <div className="animationIcon Name font-bold h-full text-xs 2xl:text-sm w-full max-w-[40%]  flex justify-center align-center px-1 overflow-hidden ">
                                  {residexData[4]?.["Cash"]?.[
                                    "Cash"
                                  ].toLocaleString("en-IN")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* PieChart Content */}
                  </div>
                ) : page === "OnchainWALR" ? (
                  <div className="w-full flex-col justify-center items-center ">
                    <div className="w-full flex justify-between items-start ">
                      <WalrChart />
                    </div>
                  </div>
                ) : page === "OnchainRESIDEX" ? (
                  <div className="w-full flex-col justify-center items-center ">
                    <div className="w-full flex justify-between items-start">
                      <ResidexChart />
                    </div>
                  </div>
                ) : page === "Coverage" ? (
                  <div className="w-full flex justify-between items-start ">
                    <CollateralChart />
                  </div>
                ) : (
                  page === "hyperledger" && (
                    <div className="w-full flex justify-center items-center ">
                      <div className=" flex flex-col items-center">
                        <div className="mb-4 max-w-sm flex justify-center">
                          <img src="/images/Error404img.jpg" alt="" />
                        </div>
                        <div className="mx-auto max-w-screen-sm text-center">
                          <h1 className="mb-3 text-xl tracking-tight font-extrabold lg:text-3xl text-[#00A4E2] ">
                            Under Construction
                          </h1>
                          <p className="mb-4 text-xl tracking-tight font-bold text-gray-900 md:text-xl ">
                            Whoops! This page will be used after migration of
                            our database to the Blockchain.
                          </p>
                          <p className="mb-2 text-lg font-light text-gray-500 ">
                            You'll find lots to explore on the home page.{" "}
                          </p>
                          <a
                            href="/"
                            className="inline-flex justify-center rounded-md bg-[#5ab2ff] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3aa0ff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Back to Homepage
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="w-[90%] mx-auto flex items-center justify-start">
              <FAQ2 />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default Custodian;
