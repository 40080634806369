import React, { useState, useEffect } from "react";
import AdminCalender from "./AdminCalender";
import EventScheduler from "./EventSchedule";

function Dashboard() {
  const [sessions, setSessions] = useState([]);
  const [adminCalender, setAdminCalender] = useState([]);

  useEffect(() => {
    fetch(`https://api-scdndestates.com/api/get-event-and-reminders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save event/reminder");
        }
        return response.json();
      })
      .then((response) => {
        setAdminCalender(response.dates);
        if (response.dates.length > 0) {
          const dates = [];
          response.dates.forEach((event) => {
            if (event.National_holidays) {
              for (const [key, value] of Object.entries(
                event.National_holidays
              )) {
                dates.push({ date: key, name: value });
              }
            }
          });

          const eventDates = [];
          response.dates.forEach((event) => {
            if (event.title !== "Next Coupon Payment Dates") {
              event?.dates?.forEach((val) => {
                eventDates.push({ date: val, name: event.title });
              });
            }
          });
        }
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
    if (adminCalender.length > 0) {
      const dates = [];
      adminCalender.forEach((event) => {
        if (event.National_holidays) {
          for (const [key, value] of Object.entries(event.National_holidays)) {
            dates.push({ date: key, name: value });
          }
        }
      });

      const eventDates = [];
      adminCalender.forEach((event) => {
        if (event.title !== "Next Coupon Payment Dates") {
          event?.dates?.forEach((val) => {
            eventDates.push({ date: val, name: event.title });
          });
        }
      });
    }

    fetch("https://api-scdndestates.com/api/logged_in_users/", {
      method: "POST",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to get the data");
        }
        return response.json();
      })
      .then((response) => {
        setSessions(response.active_sessions);
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  }, []);

  function formattedDate(date) {
    var newDate = new Date(date);
    const options = { day: "2-digit", month: "short", year: "2-digit" };
    newDate = newDate.toLocaleDateString("en-IN", options).replace(" ", "-");
    const formattedDate2 = newDate.replace(" ", "-");
    return formattedDate2;
  }

  // Function to generate dates between two dates
  const getDatesInRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    const end = new Date(endDate);

    while (currentDate <= end) {
      dates.push(formattedDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const filteredEvents = adminCalender.filter((item) => {
    const isNationalHolidays = item.hasOwnProperty("National_holidays");
    const isExcludedTitle = item.title === "Next Coupon Payment Dates";

    return !isNationalHolidays && !isExcludedTitle;
  });

  // Generate all dates for each event and flatten the array
  const eventsWithDates = filteredEvents.flatMap((event) => {
    if (event.Start_date && event.End_date) {
      const dates = getDatesInRange(event.Start_date, event.End_date);
      return dates.map((date) => ({
        ...event,
        date,
      }));
    }
    return [event];
  });

  const sortedEvents = eventsWithDates.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  const calculateKYCSum = (KYC_status) => {
    return Object.values(KYC_status).reduce((sum, value) => sum + value, 0);
  };

  return (
    <div className="fluid-container p-0 m-0">
      {/* Left Section */}
      <div className="flex justify-start">
        <div className="flex gap-3 flex-col w-full">
          {/* Event Schedule */}
          <div className="w-full h-full">
            <div className="App">
              <EventScheduler
                adminCalender={adminCalender}
                onReload={(data) => {
                  setAdminCalender(data);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="mt-3 md:flex max-md:flex-col justify-between w-full">
        {/* Calender */}
        <div className="md:w-[49%]">
          <AdminCalender
            adminCalender={adminCalender}
            onReload={(data) => {
              setAdminCalender(data);
            }}
          />
        </div>

        {/* Active User */}
        {/* {label === "Admin" && ( */}
        <div className="border bg-light shadow-md holiday-list rounded-lg bg-red-100 min-h-[310px] max-h-[310px] md:min-h-[400px] md:max-h-[400px] md:w-[49%] max-md:mt-4 md:mt-0 overflow-hidden">
          <div className="bg-[#4E83D9] text-white  px-2.5 py-2 rounded-t-lg text-base md:text-lg font-bold flex items-center justify-between">
            <span>Active Users</span>
            <span className="font-semibold text-lg text-black bg-[#DBEDF9] py-1 px-3 rounded-md">
              {sessions.length}{" "}
              <span className="text-black text-base md:text-lg font-bold">
                / 50
              </span>
            </span>
          </div>
          <div className="min-h-[260px] max-h-[260px] md:min-h-[350px] md:max-h-[350px] overflow-y-scroll">
            <ul className="mr-3 my-2 flex flex-col gap-2">
              {sessions.map((session, index) => (
                <div className="flex justify-between items-center border rounded-md pl-2">
                  <div key={index} className="flex flex-col">
                    <span className="font-bold text-black text-sm md:text-base">
                      {session.fullName}
                    </span>
                    <p className="text-xs md:text-sm font-semibold text-gray-700">
                      Login Time:{" "}
                      <span className="font-medium">
                        {session.login_time.toLocaleString()}
                      </span>
                    </p>
                  </div>
                  <div className="bg-[#DBEDF9] p-1 flex flex-col items-center justify-center rounded-md text-black">
                    <span className="text-lg md:text-xl font-bold text-center">
                      {calculateKYCSum(session.KYC_status)}%
                    </span>
                    <span className="text-xs font-semibold text-center">
                      Completed
                    </span>
                  </div>
                </div>
              ))}
            </ul>
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  );
}

export default Dashboard;
