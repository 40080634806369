import React, { useState } from "react";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import QuarterDisplay from "./QuarterDisplay.jsx";
import DateDisplay from "./DateDisplay.jsx";
import { IoCalendarSharp } from "react-icons/io5";
import { FaAngleDown } from "react-icons/fa6";
import { FaFilePdf } from "react-icons/fa";
import moment from "moment";
import Loader from "../../components/Loaders/Loader2";
import secureLocalStorage from "react-secure-storage";

function Form16A() {
  const storedData = secureLocalStorage.getItem("kycData");
  const userKycData = JSON.parse(storedData);
  const { isAuthenticated, pan, email, phone } = CheckAuth();
  const [loader, setLoader] = useState(false);
  const [pdfData, setPdfData] = useState([]);

  const calculateDateRange = (days) => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - days);
    setDates([startDate, endDate]);
  };

  const [dates, setDates] = useState([
    new Date(),
    new Date(new Date().setDate(new Date().getDate() + 31)),
  ]);

  const handleDateClick = (index) => {
    setCurrentDateIndex(index);
    setShowModal(index);
  };

  const [showModal, setShowModal] = useState(-1);
  const [currentDateIndex, setCurrentDateIndex] = useState(0);

  const handleDateChange = (newDate) => {
    const newDates = [...dates];
    newDates[currentDateIndex] = newDate;
    setDates(newDates);
    setShowModal(-1);
  };

  const handleSubmit = () => {
    if (isAuthenticated) {
      setLoader(true);
      fetch(`https://api-scdndestates.com/api/fee-tax-pdfs/`, {
        method: "POST",
        body: JSON.stringify({
          pan: pan,
          startDate: moment(dates[0]).format("YYYY-MM-DD"),
          endDate: moment(dates[1]).format("YYYY-MM-DD"),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          if (data.data === "not Found") {
            setPdfData([]);
          } else {
            setPdfData(data.data);
          }
          setLoader(false);
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const handleDownload = (filename) => {
    fetch(`https://api-scdndestates.com/api/download-form16(A)/`, {
      method: "POST",
      body: JSON.stringify({
        file: filename,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        // Return response blob instead of parsing as JSON
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  const [Form16A, setForm16A] = useState(-1);

  const [selectedRange, setSelectedRange] = useState("");

  const handleClick = (range) => {
    setSelectedRange(range);

    let startDate = new Date();
    switch (range) {
      case "1 Year":
        startDate.setFullYear(startDate.getFullYear() - 1);
        break;
      case "2 Years":
        startDate.setFullYear(startDate.getFullYear() - 2);
        break;
      case "5 Years":
        startDate.setFullYear(startDate.getFullYear() - 5);
        break;
      case "All":
        startDate = new Date("04-01-2019");
        break;
      default:
        return;
    }

    setDates([startDate, new Date()]);
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-col">
            <div className="md:flex gap-3 text-black">
              <div className="border p-4 shadow-3 rounded-lg w-full md:w-fit mt-[20px] md:mt-[0px]">
                {/* Select a Date Range */}
                <div id="date-picker-container" className="w-fit">
                  <div id="date-picker-title">
                    <h4 className="pb-3 text-xl font-bold">
                      Select a Duration :
                    </h4>
                  </div>
                  {/* Quick Access */}
                  <div className="flex flex-wrap gap-2 text-xs xl:text-sm font-semibold">
                    {["1 Year", "2 Years", "5 Years", "All"].map((range) => (
                      <p
                        key={range}
                        className={`${
                          selectedRange === range
                            ? "bg-[#D8E5F9]"
                            : "bg-zinc-200"
                        } hover:bg-[#D8E5F9] rounded-full px-3 py-0.5 cursor-pointer duration-200`}
                        onClick={() => handleClick(range)}
                      >
                        {range}
                      </p>
                    ))}
                  </div>

                  <hr className="my-2" />

                  {/* Select Date */}
                  <div
                    id="date-picker-dates"
                    className="flex justify-between relative py-3"
                  >
                    {dates?.map((date, index) => (
                      <>
                        <div
                          key={index}
                          className={`flex border px-3 py-2 items-center rounded-lg cursor-pointer gap-1 text-sm md:text-[14px] xl:text-base hover:bg-[#D8E5F9] duration-200`}
                          onClick={() => handleDateClick(index)}
                        >
                          <span className="font-semibold w-[70px] md:w-[70px] xl:w-[80px]">
                            {index === 0 ? "Start Date" : "End Date"}
                          </span>
                          <span className="ml-2 ">
                            <IoCalendarSharp size={18} />
                          </span>
                        </div>

                        <span
                          className="absolute"
                          style={{
                            top: "65px",
                            ...(index === 0
                              ? { left: "0px" }
                              : { right: "0px" }),
                          }}
                        >
                          {showModal === index && (
                            <QuarterDisplay
                              model={showModal}
                              index={currentDateIndex}
                              date={dates[currentDateIndex]}
                              onChange={handleDateChange}
                              onClose={() => setShowModal(-1)}
                            />
                          )}
                        </span>
                      </>
                    ))}
                  </div>

                  {/* Selected Date */}
                  <div
                    id="date-picker-display-container"
                    className="flex justify-between mt-2 "
                  >
                    {dates?.map((date, index) => (
                      <DateDisplay
                        key={index}
                        date={date}
                        label={`${index === 0 ? "Start " : "End "}Date`}
                      />
                    ))}
                  </div>

                  {/* Submit Button */}
                  <div
                    id="date-picker-display-container"
                    className="flex justify-center mt-4"
                  >
                    <button
                      className="bg-[#386BC0] px-4 py-1.5 rounded text-white font-semibold text-sm "
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>

              <div className="border rounded-lg w-full md:w-fit mt-[20px] md:mt-[0px]">
                <div className="bg-[#3B71CA] px-3 py-2.5 rounded-t">
                  <h2 className="font-bold text-white text-base md:text-lg">
                    Tax Certificates
                  </h2>
                </div>
                {/* Table div */}

                <div className="max-h-[370px] overflow-scroll mr-1 mt-2 md:min-w-[330px] lg:max-w-[200px] xl:min-w-[450px]">
                  {pdfData.length !== 0 ? (
                    Object.entries(pdfData).map(([fileName, data], index) => (
                      <div key={index} className="flex flex-col px-3">
                        <div className="flex items center gap-1">
                          <div
                            onClick={() => {
                              if (Form16A === index) setForm16A(-1);
                              else setForm16A(index);
                            }}
                            className=" cursor-pointer w-full flex z-2 relative justify-between items-center bg-zinc-100 px-3 py-2 md:gap-[50px] lg:gap-[150px] rounded-lg hover:bg-[#D8E5F9] duration-200 border font-bold"
                          >
                            <p>
                              {fileName.substring(
                                fileName.lastIndexOf("_") + 1
                              )}
                            </p>
                            <span className="flex items-center gap-2">
                              <FaAngleDown
                                className={`${
                                  Form16A === index ? "rotate-180" : ""
                                } flex items-center gap-2 duration-300`}
                                onClick={() => {
                                  if (Form16A === index) setForm16A(-1);
                                  else setForm16A(index);
                                }}
                              />
                            </span>
                          </div>
                          <span
                            onClick={() => handleDownload(fileName)}
                            className="bg-zinc-100 px-2 py-[11px] border rounded-lg cursor-pointer"
                          >
                            <FaFilePdf className="cursor-pointer text-red-700" />
                          </span>
                        </div>

                        {/* Custodian Data */}
                        <div
                          className={`mr-10 md:mr-[13%] lg:mr-[1.8%] xl:mr-[10%] flex relative flex-wrap justify-between px-3 gap-2 border-x-2 py-2 border-b font-semibold rounded-b-lg transition-transform duration-300 ease-in-out accordionItem ${
                            Form16A === index
                              ? "is-expanded animateIn mb-2"
                              : "animateOut"
                          }`}
                        >
                          {Object.entries(data).map(
                            ([key, value], subIndex) => (
                              <div
                                key={subIndex}
                                className="flex justify-between"
                              >
                                <p>{key} :</p>
                                <p className="font-bold">
                                  &nbsp;₹{" "}
                                  {parseFloat(value).toLocaleString("en-IN")}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex justify-center items-center px-2  min-h-[330px] md:min-w-[250px] lg:min-w-[400px] overflow-hidden ">
                      <div className="animate-pulse flex flex-col justify-center items-center px-2 gap-y-4 overflow-hidden">
                        <img
                          className="w-32 h-32 "
                          src="/images/no-info.svg"
                          alt=""
                        />
                        <span className="text-center text-xs md:text-sm   font-semibold">
                          {" "}
                          You don't have any Coupon Statements.
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Form16A;
