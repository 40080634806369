import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar.jsx";
import SideBar from "./SideBar.jsx";
import DatabaseOperations from "./DatabaseOperations.jsx";
import ManageDocuments from "./ManageDocument.jsx";
import "./admin.css";
import Dashboard from "./dashboard.jsx";
import AssetLiabilityMatch from "./AssetLiabilityMatch.jsx";
import UserDocuments from "./UserDocuments.jsx";
import AgentsComissions from "./AgentsComissions.jsx";

function Admin() {
  const [menuItem, setMenuItem] = useState(0);
  const [sideBar, setSideBar] = useState(false);
  const navigate = useNavigate();

  const handleMenuItem = (item) => {
    if (item === 8) {
      navigate("/TradingPortal");
    }
    setMenuItem(item);
  };

  return (
    <>
      <div className="">
        <Navbar
          handleSidebar={() => {
            setSideBar(!sideBar);
          }}
        />
        <div className="container-fluid p-0 m-0 flex">
          <SideBar
            className=""
            sideBar={sideBar}
            handleMenuItem={handleMenuItem}
          />
          <div className="after-admin-header overflow-hidden  pt-[70px]  px-2 pb-3 lg:pl-2 w-[100%] bg-[#F1F3FD]">
            <div className="mt-1 p-3 lg:p-4 bg-white h-full rounded-xl">
              {menuItem === 0 ? (
                <Dashboard />
              ) : menuItem === 1 ? (
                <ManageDocuments />
              ) : menuItem === 2 ? (
                <DatabaseOperations />
              ) : menuItem === 3 ? (
                <AssetLiabilityMatch />
              ) : menuItem === 9 ? (
                <UserDocuments />
              ) : menuItem === 4 ? (
                <AgentsComissions />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
