import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { HiCurrencyRupee } from "react-icons/hi2";
import ReactDOMServer from "react-dom/server";

const WalrPieChart = ({ data, showContent }) => {
  const svgRef = useRef();

  useEffect(() => {
    const svgElement = d3.select(svgRef.current);

    const renderChart = () => {
      // Clear previous SVG content
      svgElement.selectAll("*").remove();

      const pieData = data?.sort((a, b) => b.value - a.value);

      const containerWidth = svgElement.node().parentNode.offsetWidth;
      const containerHeight = containerWidth * 0.7; // Maintain aspect ratio
      const thickness = containerWidth * 0.1; // Adjust thickness based on container width
      const radius = Math.min(containerWidth, containerHeight) / 1.95;

      const svg = svgElement
        .append("svg")
        .attr("viewBox", `0 0 ${containerWidth} ${containerHeight}`)
        .attr("width", containerWidth)
        .attr("height", containerHeight);

      const defs = svg.append("defs");

      // Create gradients
      pieData.forEach((d, i) => {
        const gradient = defs
          .append("linearGradient")
          .attr("id", `gradient-${i}`)
          .attr("x1", "0%")
          .attr("x2", "100%");

        gradient
          .append("stop")
          .attr("offset", "0%")
          .attr("stop-color", d.color);

        gradient
          .append("stop")
          .attr("offset", "100%")
          .attr("stop-color", d3.color(d.color).darker(-0.7));
      });

      const g = svg
        .append("g")
        .attr(
          "transform",
          `translate(${containerWidth / 2}, ${containerHeight / 1.3})`
        );

      const arc = d3
        .arc()
        .innerRadius(radius - thickness + 3)
        .outerRadius(radius)
        .cornerRadius(5); // Adjust this value for the desired corner radius

      const arcHover = d3
        .arc()
        .innerRadius(radius - thickness + 3)
        .outerRadius(radius + 15)
        .cornerRadius(5); // Keep the same corner radius

      const pie = d3
        .pie()
        .startAngle(-Math.PI / 2)
        .endAngle(Math.PI / 2)
        .value((d) => d.value)
        .sort(null)
        .padAngle(0.02);

      const paths = g
        .selectAll("path")
        .data(pie(pieData))
        .enter()
        .append("g")
        .each(function (d, i) {
          const group = d3.select(this);

          // Append the path with hover effects
          const path = group
            .append("path")
            .attr("d", arc)
            .attr("fill", `url(#gradient-${i})`)
            .attr("class", "data-path2");

          // Hover effect
          path.on("mouseover", function (event, d) {
            showContent(d.data.name);
            d3.selectAll(".data-text2").classed("data-text--show2", false);
            d3.selectAll(".data-path2")
              .transition()
              .duration(250)
              .attr("d", arc);

            d3.select(this).transition().duration(250).attr("d", arcHover);

            d3.select(this.parentNode)
              .select(".data-text__value2")
              .classed("data-text--show2", true);

            d3.select(this.parentNode)
              .select(".data-text2")
              .classed("data-text--show2", true);
          });

          group
            .append("text")
            .text(`${parseInt(d.data.value).toLocaleString("en-IN")}`)
            .attr("class", "data-text2 data-text__value2")
            .attr("text-anchor", "middle");

          // Append the icon using foreignObject
          group
            .append("foreignObject")
            .attr("width", 55)
            .attr("height", 55)
            .attr("x", -25)
            .attr("y", -85)
            .append("xhtml:div")
            .style("font-size", "40px") // Adjust size as needed
            .style("display", "flex")
            .style("justify-content", "center")
            .style("align-items", "center")
            .style("color", "#ffa500")
            .html(() =>
              ReactDOMServer.renderToString(
                <HiCurrencyRupee className="text-[#0590F8]" />
              )
            );
        });

      const legendRectSize = containerWidth * 0.04;
      const legendSpacing = containerWidth * 0.03;

      // Legend container
      const legendContainer = svg.append("g").attr("class", "legend-container");

      const legend = legendContainer
        .selectAll(".legend2")
        .data(pieData)
        .enter()
        .append("g")
        .attr("class", "legend2")
        .attr("transform", function (d, i) {
          const horz = i * (legendRectSize + legendSpacing * 9);
          const vert = legendRectSize / 8; // Positioning legends at the top
          return `translate(${horz}, ${vert})`;
        });

      legend
        .append("circle")
        .attr("r", legendRectSize / 2.5)
        .style("fill", (d, i) => `url(#gradient-${i})`);

      legend
        .append("text")
        .attr("x", legendRectSize)
        .attr("y", legendRectSize / 3.5)
        .attr("class", "legend-text2")
        .text((d) => d.name)
        .style("font-size", `12px`);
    };

    renderChart();

    // Redraw chart on window resize
    window.addEventListener("resize", renderChart);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", renderChart);
    };
  }, [JSON.stringify(data)]);

  return <div className="w-[450px] lg:w-[500px]" ref={svgRef}></div>;
};

export default WalrPieChart;
