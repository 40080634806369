import React, { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./EventScheduler.css";
import { IoClose, IoTrashBin } from "react-icons/io5";
import { MdSkipNext, MdSkipPrevious } from "react-icons/md";
import Loader from "../components/Loaders/Loader1.jsx";
import CheckAuth from "../components/ServiceFunctions/CheckAuth.jsx";
import { MdSecurityUpdateGood } from "react-icons/md";
import AlertYellow from "../components/Alerts/AlertYellow.jsx";
import AlertRed from "../components/Alerts/AlertRed.jsx";
import AlertGreen from "../components/Alerts/AlertGreen.jsx";
import secureLocalStorage from "react-secure-storage";

const EventScheduler = ({ adminCalender, onReload }) => {
  const { phone } = CheckAuth();
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDesc, setSelectedDesc] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedBlocker, setEditedBlocker] = useState("Blocker text here...");
  const [selectedRate, setSelectedRate] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [showTitle, setShowTitle] = useState(null);
  const [assignees, setAssignees] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [allPriority, setAllPriority] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [changeRate, setChangeRate] = useState(-1);
  const [changeDuration, setChangeDuration] = useState(-1);
  const [isFilterOpen1, setIsFilterOpen1] = useState(false);
  const [assignee, setAssignee] = useState("All");
  const [status, setStatus] = useState("All");
  const [priority, setPriority] = useState("All");
  const [generatedRatingOtp, setGeneratedRatingOtp] = useState("");
  const [generatedDurationOtp, setGeneratedDurationOtp] = useState("");
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [showModal, setShowModal] = useState(-1);
  const [resendDurationTimer, setResendDurationTimer] = useState(90);
  const [resendRatingTimer, setResendRatingTimer] = useState(90);
  const [show1, setShow1] = useState(false);
  const timerRef = useRef(null);
  const [timer, setTimer] = useState(300);
  const [userOtp, setUserOtp] = useState();
  const [otp, setOtp] = useState();
  const rowsPerPage = 7;

  useEffect(() => {
    if (showPopup || show1) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showPopup, show1]);

  useEffect(() => {
    setLoader(true);
    const eventsOnDate = adminCalender
      .filter(
        (event) =>
          !event.National_holidays &&
          !event.BirthDays &&
          event.title !== "Next Coupon Payment Dates"
      )
      .map((event) => ({
        event_date: event.Date,
        title: event.title,
        assignee: event.Assignee,
        desc: event.Description,
        blocker: event.Blocker,
        rating: event.Rating,
        priority: event.Priority,
        status: event.Status,
        duration: event.Duration,
      }))
      .sort((a, b) => new Date(a.event_date) - new Date(b.event_date));

    setAssignees([...new Set(eventsOnDate.map((event) => event.assignee))]);
    setAllStatus([...new Set(eventsOnDate.map((event) => event.status))]);
    setAllPriority([...new Set(eventsOnDate.map((event) => event.priority))]);
    const filteredEvents = eventsOnDate.filter((event) => {
      const assigneeMatch = assignee === "All" || event.assignee === assignee;
      const statusMatch = status === "All" || event.status === status;
      const priorityMatch = priority === "All" || event.priority === priority;
      return assigneeMatch && statusMatch && priorityMatch;
    });
    setEvents(filteredEvents);

    // Calculate default page based on today's date or upcoming event
    const today = new Date();
    const firstUpcomingEventIndex = filteredEvents.findIndex(
      (event) => new Date(event.event_date) >= today
    );
    if (firstUpcomingEventIndex !== -1) {
      const defaultPage = Math.ceil(
        (firstUpcomingEventIndex + 1) / rowsPerPage
      );
      setCurrentPage(defaultPage);
    } else {
      setCurrentPage(1);
    }
    setLoader(false);
  }, [adminCalender, assignee, status, priority]);

  const handleDeleteEvent = (val) => {
    if (userOtp === otp) {
      setLoader2(true);
      fetch(`https://api-scdndestates.com/api/delete-event-and-reminders/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          val,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to delete event/reminder");
          }
          return response.json();
        })
        .then((response) => {
          if (response.message === "Success") {
            setShowModal(2);
            setTimeout(() => {
              setShowModal(-1);
              setLoader2(false);
            }, 3000);
            setShow1(false);
            secureLocalStorage.setItem(
              "admin-calender",
              JSON.stringify(response.dates)
            );
            onReload(response.dates);
          }
          setUserOtp("");
        })
        .catch((error) => {
          // console.error("Error during logout:", error);
        });
    } else {
      setShowModal(1);
      setTimeout(() => {
        setShowModal(-1);
      }, 3000);
    }
  };

  const handleEvent = (title, key, value) => {
    setEvents((prevEvents) =>
      prevEvents.map((event) =>
        event.title === title ? { ...event, [key.toLowerCase()]: value } : event
      )
    );
    fetch(`https://api-scdndestates.com/api/edit-event-and-reminders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        key,
        value,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete event/reminder");
        }
        return response.json();
      })
      .then((response) => {
        onReload(response.dates);
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(events.length / rowsPerPage)) {
      setCurrentPage(page);
    }
  };

  function formattedDate(dateString) {
    const date = new Date(dateString);
    const monthShortName = date.toLocaleString("en-IN", { month: "short" });
    const day = String(date.getDate()).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2); // Last two digits of the year
    return `${day}-${monthShortName}-${year}`;
  }

  const renderRows = () => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return events.length > 0 ? (
      events.slice(start, end).map((event, index) =>
        loader ? (
          <div className="relative flex left-[15%] md:left-[42%] md:-translate-[50%]">
            <Loader />
          </div>
        ) : (
          <div
            key={index}
            className={`w-full flex items-center border-b border-gray-200 hover:bg-slate-100 transition duration-200 cursor-pointer text-[#313131] font-semibold text-sm ${
              isAnimating ? "" : "table-fade-in"
            }`}
          >
            <div className="p-2 w-[100px] xl:w-[11%]">
              <div className="w-full overflow-hidden whitespace-nowrap bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none cursor-not-allowed">
                {formattedDate(event.event_date)}
              </div>
            </div>
            <div
              className="p-2 w-[300px] xl:w-[40%]"
              onClick={() =>
                handleRowClick(
                  event.desc,
                  event.blocker,
                  event.rating,
                  event.title,
                  event.duration,
                  event.assignee,
                  formattedDate(event.event_date)
                )
              }
            >
              <div
                onClick={() => setShowTitle(event.title)}
                className="w-full overflow-hidden whitespace-nowrap bg-gray-50 p-1.5 border border-gray-300 rounded ring-2 ring-[#cbefff] focus:outline-none"
              >
                {event?.title?.length > 50
                  ? `${event?.title?.slice(0, 50)}...`
                  : event?.title}
              </div>
            </div>
            <div className="p-2 w-[150px] xl:w-[12%]">
              <div
                type="text"
                className="w-full overflow-hidden whitespace-nowrap bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none cursor-not-allowed"
              >
                {event.assignee}
              </div>
            </div>
            <div className="p-2 w-[150px] xl:w-[12%]">
              <div
                type="text"
                className="w-full overflow-hidden whitespace-nowrap  bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none cursor-not-allowed"
              >
                {event.assignee}
              </div>
            </div>
            <div className="p-2 w-[100px] xl:w-[9%]">
              <div className="flex w-full overflow-hidden whitespace-nowrap ">
                <div className="w-4/12 flex items-center justify-center">
                  <div
                    className={`${
                      event.priority === "P0"
                        ? "bg-blue-950"
                        : event.priority === "P1"
                        ? "bg-blue-800"
                        : event.priority === "P2"
                        ? "bg-blue-600"
                        : event.priority === "P3"
                        ? "bg-blue-400"
                        : event.priority === "P4"
                        ? "bg-blue-300"
                        : event.priority === "P5" && "bg-blue-200"
                    } w-3 h-3 rounded-full `}
                  ></div>
                </div>
                <select
                  value={event.priority || "P0"}
                  onChange={(e) =>
                    handleEvent(event.title, "Priority", e.target.value)
                  }
                  className="w-full overflow-hidden whitespace-nowrap bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                >
                  <option>P0</option>
                  <option>P1</option>
                  <option>P2</option>
                  <option>P3</option>
                  <option>P4</option>
                  <option>P5</option>
                  <option>P6</option>
                </select>
              </div>
            </div>
            <div className="p-2 w-[140px] xl:w-[13%]">
              <select
                value={event.status || "Not Started"}
                onChange={(e) =>
                  handleEvent(event.title, "Status", e.target.value)
                }
                className="w-full overflow-hidden whitespace-nowrap bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
              >
                <option>Not Started</option>
                <option>In Progress</option>
                <option>Defective</option>
                <option>Pending</option>
                <option>Postponed</option>
                <option>Completed</option>
              </select>
            </div>
            <div className="p-2 w-[50px] xl:w-[3%]">
              <div className="flex items-center justify-center">
                <svg
                  onClick={() => {
                    sendDeleteOtp(event.title);
                    setSelectedTitle(event.title);
                    setSelectedDate(event.event_date);
                    setTimer(90);
                    handleTimer();
                  }}
                  className="w-5 h-5 p-1 bg-slate-50 rounded-full  hover:bg-red-600 hover:fill-[#ffffff] fill-[#E4302B] hover:scale-90  duration-300 cursor-pointer"
                  viewBox="0 0 365.71733 365"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_1828665"
                >
                  <g>
                    <path d="m356.339844 296.347656-286.613282-286.613281c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503906-12.5 32.769532 0 45.25l286.613281 286.613282c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082032c12.523438-12.480468 12.523438-32.75.019532-45.25zm0 0"></path>
                    <path d="m295.988281 9.734375-286.613281 286.613281c-12.5 12.5-12.5 32.769532 0 45.25l15.082031 15.082032c12.503907 12.5 32.769531 12.5 45.25 0l286.632813-286.59375c12.503906-12.5 12.503906-32.765626 0-45.246094l-15.082032-15.082032c-12.5-12.523437-32.765624-12.523437-45.269531-.023437zm0 0"></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        )
      )
    ) : (
      <div className=" text-sm md:text-base">No Events Scheduled yet...</div>
    );
  };

  const handleRowClick = (
    desc,
    blocker,
    rating,
    title,
    duration,
    assignee,
    date
  ) => {
    setSelectedDesc(desc);
    setEditedBlocker(blocker);
    setSelectedRate(rating);
    setSelectedDuration(duration);
    setSelectedTitle(title);
    setSelectedAssignee(assignee);
    setSelectedDate(date);
    setShowPopup(true);
    setChangeDuration(-1);
    setChangeRate(-1);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  };

  const handleTimer = () => {
    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(timerRef.current);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  const toggleFilter = () => setIsFilterOpen(!isFilterOpen);
  // use effect for filter close
  const filterRef = useRef(null); // Use a ref to reference the filter dropdown

  const handleResendDurationTimer = () => {
    setResendDurationTimer(90);

    const timerInterval = setInterval(() => {
      setResendDurationTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
    }, 90000);
  };

  const handleResendRatingTimer = () => {
    setResendRatingTimer(90);

    const timerInterval = setInterval(() => {
      setResendRatingTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
    }, 90000);
  };

  const sendRatingOtp = () => {
    if (phone.length !== 10) {
      setShowModal(3);
      setTimeout(() => {
        setShowModal(-1);
      }, 5000);
    } else {
      fetch(`https://api-scdndestates.com/api/work-rating-otp/`, {
        method: "POST",
        body: JSON.stringify({
          title: selectedTitle,
          assignee: selectedAssignee,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send otp");
          }
          return response.json();
        })
        .then((response) => {
          if (response.error === "Phone number not found") {
            setShowModal(4);
            setTimeout(() => {
              setShowModal(-1);
            }, 5000);
          } else if (response.error === "task not completed") {
            setShowModal(5);
            setTimeout(() => {
              setShowModal(-1);
            }, 5000);
          } else {
            setGeneratedRatingOtp(response.otp);
            handleResendRatingTimer();
          }
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const sendDeleteOtp = (val) => {
    if (phone?.length !== 10) {
      setShowModal(3);
      setTimeout(() => {
        setShowModal(-1);
      }, 5000);
    } else {
      fetch(`https://api-scdndestates.com/api/work-delete-otp/`, {
        method: "POST",
        body: JSON.stringify({
          title: val,
          phone: "91" + phone,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send otp");
          }
          return response.json();
        })
        .then((response) => {
          if (response.error === "Phone number not found") {
            setShowModal(4);
            setTimeout(() => {
              setShowModal(-1);
            }, 5000);
          } else if (response.error === "task not completed") {
            setShowModal(5);
            setTimeout(() => {
              setShowModal(-1);
            }, 5000);
          } else {
            setOtp(response.otp);
            setShow1(true);
          }
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const sendDurationOtp = () => {
    if (phone.length !== 10) {
      setShowModal(3);
      setTimeout(() => {
        setShowModal(-1);
      }, 5000);
    } else {
      fetch(`https://api-scdndestates.com/api/work-duration-otp/`, {
        method: "POST",
        body: JSON.stringify({
          phone: "91" + phone,
          title: selectedTitle,
          date: selectedDate,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send otp");
          }
          return response.json();
        })
        .then((response) => {
          if (response.error === "Phone number not found") {
            setShowModal(4);
            setTimeout(() => {
              setShowModal(-1);
            }, 5000);
          } else if (response.error === "task not completed") {
            setShowModal(5);
            setTimeout(() => {
              setShowModal(-1);
            }, 5000);
          } else {
            setGeneratedDurationOtp(response.otp);
            handleResendDurationTimer();
          }
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const verifyGeneratedOtp = (type, userOtp) => {
    const otp = type === "rating" ? generatedRatingOtp : generatedDurationOtp;
    if (otp === userOtp) {
      if (type === "rating") {
        handleEvent(selectedTitle, "Rating", selectedRate);
        setChangeRate(1);
        setTimeout(() => {
          setChangeRate(-1);
          setGeneratedRatingOtp("");
        }, 5000);
      } else {
        handleEvent(selectedTitle, "Duration", selectedDuration);
        setChangeDuration(1);
        setTimeout(() => {
          setChangeDuration(-1);
          setGeneratedDurationOtp("");
        }, 5000);
      }
    } else {
      setShowModal(1);
    }
    setTimeout(() => {
      setShowModal(-1);
    }, 5000);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterOpen(false); // Close the filter dropdown if the click is outside of it
      }
    };

    // Attach the event listener
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full mx-auto shadow-[0px_1px_4px_1px_#00000024] bg-sky-600 rounded-lg relative">
      {/* Title and Pagination Control */}
      <div className="flex justify-between items-center py-2.5 px-2 md:px-3">
        <h1 className="text-base md:text-lg font-bold text-[#ffffff]">
          Sprint
        </h1>
        <div className="flex gap-5 items-center relative">
          {/* {label === "Admin" && ( */}
          <div ref={filterRef} className="relative z-[10]  max-md:hidden">
            {/* Increased z-index here */}
            <button
              onClick={toggleFilter}
              className="flex bg-sky-100 hover:bg-[#BAE6FC] gap-1 pl-1 py-1 pr-2 rounded text-sm font-semibold items-center hover:scale-95 duration-300"
            >
              <span className="rotate-90">
                <svg
                  id="fi_9702724"
                  className="w-4 h-4 text-black"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsSvg="http://www.w3.org/2000/svg"
                >
                  <g
                    id="layer1"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="4.1"
                    transform="translate(-300 -100)"
                  >
                    <path
                      id="path6012"
                      d="m321 106.01215c-2.40719 0-4.43155 1.72541-4.89844 4h-13.10156c-.55228 0-1 .44772-1 1s.44772 1 1 1h13.10156c.46689 2.27459 2.49125 4 4.89844 4s4.43155-1.72541 4.89844-4h3.10156c.55228 0 1-.44772 1-1s-.44772-1-1-1h-3.10156c-.46689-2.27459-2.49125-4-4.89844-4zm0 2c1.66867 0 3 1.33133 3 3s-1.33133 3-3 3-3-1.33133-3-3 1.33133-3 3-3z"
                    ></path>
                    <path
                      id="path6014"
                      d="m311 116.01215c-2.40719 0-4.43155 1.72541-4.89844 4h-3.10156c-.55228 0-1 .44772-1 1s.44772 1 1 1h3.10156c.46689 2.27459 2.49125 4 4.89844 4s4.43155-1.72541 4.89844-4h13.10156c.55228 0 1-.44772 1-1s-.44772-1-1-1h-13.10156c-.46689-2.27459-2.49125-4-4.89844-4zm0 2c1.66867 0 3 1.33133 3 3s-1.33133 3-3 3-3-1.33133-3-3 1.33133-3 3-3z"
                    ></path>
                  </g>
                </svg>
              </span>
              Filter
            </button>
            {isFilterOpen && (
              <div className="alert-animation absolute right-0 z-[50] mt-2 w-full bg-white border border-gray-200 rounded-md shadow-lg min-w-[200px] ">
                <div className="p-2.5 space-y-3 z-20">
                  {/* Assignee Dropdown */}
                  <div>
                    <label className="block text-sm font-semibold text-gray-800">
                      Assignee
                    </label>
                    <select
                      value={assignee}
                      onChange={(e) => {
                        setAssignee(e.target.value);
                        if (e.target.value) {
                          setIsFilterOpen(false);
                        }
                      }}
                      className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="All">All</option>
                      {assignees.map((a) => (
                        <option key={a} value={a}>
                          {a}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Status Dropdown */}
                  <div>
                    <label className="block text-sm font-semibold text-gray-800">
                      Status
                    </label>
                    <select
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                        if (e.target.value) {
                          setIsFilterOpen(false);
                        }
                      }}
                      className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="All">All</option>
                      {allStatus.map((a) => (
                        <option key={a} value={a}>
                          {a}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Priority Dropdown */}
                  <div>
                    <label className="block text-sm font-semibold text-gray-800">
                      Priority
                    </label>
                    <select
                      value={priority}
                      onChange={(e) => {
                        setPriority(e.target.value);
                        if (e.target.value) {
                          setIsFilterOpen(false);
                        }
                      }}
                      className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="All">All</option>
                      {allPriority
                        .sort((a, b) => {
                          const numA = parseInt(a.substring(1), 10);
                          const numB = parseInt(b.substring(1), 10);
                          return numA - numB;
                        })
                        .map((a) => (
                          <option key={a} value={a}>
                            {a}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* )} */}
          {/* Side bar */}
          <div className="md:hidden pl-[120px]">
            {/* Increased z-index here */}
            <button
              onClick={() => setIsFilterOpen1(true)}
              className="flex bg-sky-100 hover:bg-[#BAE6FC] gap-1 p-1 rounded text-sm font-semibold items-center hover:scale-95 duration-300"
            >
              <span className="rotate-90">
                <svg
                  id="fi_9702724"
                  className="w-4 h-4 text-black"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsSvg="http://www.w3.org/2000/svg"
                >
                  <g
                    id="layer1"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="4.1"
                    transform="translate(-300 -100)"
                  >
                    <path
                      id="path6012"
                      d="m321 106.01215c-2.40719 0-4.43155 1.72541-4.89844 4h-13.10156c-.55228 0-1 .44772-1 1s.44772 1 1 1h13.10156c.46689 2.27459 2.49125 4 4.89844 4s4.43155-1.72541 4.89844-4h3.10156c.55228 0 1-.44772 1-1s-.44772-1-1-1h-3.10156c-.46689-2.27459-2.49125-4-4.89844-4zm0 2c1.66867 0 3 1.33133 3 3s-1.33133 3-3 3-3-1.33133-3-3 1.33133-3 3-3z"
                    ></path>
                    <path
                      id="path6014"
                      d="m311 116.01215c-2.40719 0-4.43155 1.72541-4.89844 4h-3.10156c-.55228 0-1 .44772-1 1s.44772 1 1 1h3.10156c.46689 2.27459 2.49125 4 4.89844 4s4.43155-1.72541 4.89844-4h13.10156c.55228 0 1-.44772 1-1s-.44772-1-1-1h-13.10156c-.46689-2.27459-2.49125-4-4.89844-4zm0 2c1.66867 0 3 1.33133 3 3s-1.33133 3-3 3-3-1.33133-3-3 1.33133-3 3-3z"
                    ></path>
                  </g>
                </svg>
              </span>
            </button>
          </div>
          <div className="max-md:hidden md:flex space-x-4 items-center">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
                currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Previous
            </button>
            <span className="text-[#ffffff] text-sm">
              <span className="font-semibold">Page {currentPage}</span> of{" "}
              {Math.ceil(events.length / rowsPerPage)}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(events.length / rowsPerPage)}
              className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
                currentPage === Math.ceil(events.length / rowsPerPage)
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              Next
            </button>
          </div>
        </div>
        <div className="max-md:flex md:hidden space-x-4 items-center">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
              currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <MdSkipPrevious />
          </button>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === Math.ceil(events.length / rowsPerPage)}
            className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
              currentPage === Math.ceil(events.length / rowsPerPage)
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
          >
            <MdSkipNext />
          </button>
        </div>
      </div>

      {/* Event Table */}
      <div className="w-full overflow-scroll bg-white shadow-md">
        <div className="min-w-[990px] w-full">
          <div className="bg-sky-100 text-[#212121] flex text-sm uppercase leading-normal">
            <div className="p-2.5 text-left font-bold w-[100px] xl:w-[11%]">
              Date
            </div>
            <div className="p-2.5 text-left font-bold w-[300px] xl:w-[40%]">
              Task
            </div>
            <div className="p-2.5 text-left font-bold w-[150px] xl:w-[12%]">
              Assignee
            </div>
            <div className="p-2.5 text-left font-bold w-[150px] xl:w-[12%]">
              Dependency
            </div>
            <div className="p-2.5 text-left font-bold w-[100px] xl:w-[9%]">
              Priority
            </div>
            <div className="p-2.5 text-left font-bold w-[140px] xl:w-[13%]">
              Status
            </div>
            <div className="p-2.5 text-center font-bold  text-red-600 w-[50px] xl:w-[3%]">
              <IoTrashBin />
            </div>
          </div>
          <div className={`${isAnimating ? "" : "table-fade-in"}`}>
            {renderRows()}
          </div>
        </div>
      </div>

      {/* Side bar option */}
      <div
        className={`md:hidden absolute -left-6 -top-8 h-screen z-[5] mt-2 w-full bg-white border border-gray-200 rounded-md  max-w-[260px] transition-transform duration-500 ease-in-out ${
          isFilterOpen1 ? "translate-x-0 shadow-lg" : "-translate-x-full"
        }`}
      >
        <div className="p-4 space-y-3 z-20">
          <div className="flex items-center justify-between">
            <p className="text-[15px] font-bold text-black">Filter</p>

            <div
              onClick={() => setIsFilterOpen1(false)}
              className=" flex justify-end p-0"
            >
              <button
                className="circleadmin"
                data-animation="showShadow"
                data-remove="3000"
              ></button>
            </div>
          </div>
          <hr />
          {/* Assignee Dropdown */}
          <div>
            <label className="block text-sm font-semibold text-gray-800">
              Assignee
            </label>
            <select
              value={assignee}
              onChange={(e) => {
                setAssignee(e.target.value);
                if (e.target.value) {
                  setIsFilterOpen1(false);
                }
              }}
              className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="All">All</option>
              {assignees.map((a) => (
                <option key={a} value={a}>
                  {a}
                </option>
              ))}
            </select>
          </div>

          {/* Status Dropdown */}
          <div>
            <label className="block text-sm font-semibold text-gray-800">
              Status
            </label>
            <select
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
                if (e.target.value) {
                  setIsFilterOpen1(false);
                }
              }}
              className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="All">All</option>
              {allStatus.map((a) => (
                <option key={a} value={a}>
                  {a}
                </option>
              ))}
            </select>
          </div>

          {/* Priority Dropdown */}
          <div>
            <label className="block text-sm font-semibold text-gray-800">
              Priority
            </label>
            <select
              value={priority}
              onChange={(e) => {
                setPriority(e.target.value);
                if (e.target.value) {
                  setIsFilterOpen(false);
                }
              }}
              className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="All">All</option>
              {allPriority
                .sort((a, b) => {
                  const numA = parseInt(a.substring(1), 10);
                  const numB = parseInt(b.substring(1), 10);
                  return numA - numB;
                })
                .map((a) => (
                  <option key={a} value={a}>
                    {a}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>

      {/* Popup */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 ">
          <div className="bg-white  rounded-lg w-[90%] md:min-w-[600px]  max-w-md pop-up-animation">
            <div className="flex justify-between px-4 py-2.5 border-b-2 shadow-md">
              {" "}
              <h2 className="text-base md:text-lg font-semibold text-black flex items-center gap-2">
                <span className="">
                  <svg
                    id="fi_18401969"
                    className="w-6 h-6"
                    viewBox="0 0 64 64"
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 1"
                  >
                    <path d="m2.55 13.33v43.18a5.5 5.5 0 0 0 5.5 5.49h47.9a5.5 5.5 0 0 0 5.5-5.49v-43.18a5.5 5.5 0 0 0 -5.5-5.49h-2.8v-1.47a4.4 4.4 0 0 0 -1.29-3.09 4.363 4.363 0 0 0 -7.45 3.09v1.47h-24.82v-1.47a4.37 4.37 0 1 0 -8.74 0v1.47h-2.8a5.5 5.5 0 0 0 -5.5 5.49zm43.86-6.96a2.375 2.375 0 0 1 2.37-2.37 2.338 2.338 0 0 1 1.66.69 2.372 2.372 0 0 1 .71 1.68v5.97a2.37 2.37 0 1 1 -4.74 0zm-33.56 0a2.369 2.369 0 0 1 4.04-1.68 2.4 2.4 0 0 1 .7 1.68v5.97a2.37 2.37 0 1 1 -4.74 0zm-8.3 17.02h54.9v33.12a3.5 3.5 0 0 1 -3.5 3.49h-47.9a3.5 3.5 0 0 1 -3.5-3.49z"></path>
                    <path d="m15.78 34.8a5.15 5.15 0 1 0 -5.16-5.15 5.155 5.155 0 0 0 5.16 5.15zm0-8.3a3.15 3.15 0 1 1 -3.16 3.15 3.156 3.156 0 0 1 3.16-3.15z"></path>
                    <path d="m32 34.8a5.15 5.15 0 1 0 -5.15-5.15 5.155 5.155 0 0 0 5.15 5.15zm0-8.3a3.15 3.15 0 1 1 -3.15 3.15 3.15 3.15 0 0 1 3.15-3.15z"></path>
                    <path d="m48.22 34.8a5.15 5.15 0 1 0 -5.15-5.15 5.158 5.158 0 0 0 5.15 5.15zm0-8.3a3.15 3.15 0 1 1 -3.15 3.15 3.158 3.158 0 0 1 3.15-3.15z"></path>
                    <path d="m15.78 46.62a5.155 5.155 0 1 0 -5.16-5.15 5.155 5.155 0 0 0 5.16 5.15zm0-8.31a3.155 3.155 0 1 1 -3.16 3.16 3.165 3.165 0 0 1 3.16-3.16z"></path>
                    <path d="m32 46.62a5.155 5.155 0 1 0 -5.15-5.15 5.155 5.155 0 0 0 5.15 5.15zm0-8.31a3.155 3.155 0 1 1 -3.15 3.16 3.158 3.158 0 0 1 3.15-3.16z"></path>
                    <path d="m48.22 46.62a5.155 5.155 0 1 0 -5.15-5.15 5.158 5.158 0 0 0 5.15 5.15zm0-8.31a3.155 3.155 0 1 1 -3.15 3.16 3.167 3.167 0 0 1 3.15-3.16z"></path>
                    <path d="m15.78 58.43a5.15 5.15 0 1 0 -5.16-5.15 5.155 5.155 0 0 0 5.16 5.15zm0-8.3a3.15 3.15 0 1 1 -3.16 3.15 3.156 3.156 0 0 1 3.16-3.15z"></path>
                    <path d="m32 58.43a5.15 5.15 0 1 0 -5.15-5.15 5.155 5.155 0 0 0 5.15 5.15zm0-8.3a3.15 3.15 0 1 1 -3.15 3.15 3.15 3.15 0 0 1 3.15-3.15z"></path>
                  </svg>
                </span>
                Event Details
              </h2>
              <div
                onClick={() => closePopup(false)}
                className=" flex justify-end  p-0 m-0 rounded-full"
              >
                <button
                  className="circleadmin bg-white"
                  data-animation="showShadow"
                  data-remove="3000"
                ></button>
              </div>
            </div>
            <div className="p-6 bg-white text-sm md:text-base rounded-b-lg">
              <div className="flex flex-col mb-2">
                <span className="font-semibold text-black">Title:</span>
                <span className="border rounded p-1 font-medium ">
                  {showTitle}
                </span>
              </div>
              <div className="mb-2">
                <span className="font-semibold text-black">Description:</span>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded min-h-[200px]"
                  defaultValue={selectedDesc || "Description text here..."}
                  rows="3"
                  readOnly
                />
              </div>
              <div>
                <span className="flex font-semibold text-black">
                  Blockers (if any):
                </span>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="Why this project could not be finished in time..."
                  value={editedBlocker} // Show the editing state value
                  onChange={(e) => setEditedBlocker(e.target.value)} // Update the editedBlocker state
                  rows="3"
                  readOnly={!isEditing}
                />
              </div>

              <div className="flex justify-end mt-1">
                {isEditing ? (
                  <button
                    className="px-2 py-1 bg-sky-400 text-white text-sm rounded hover:bg-sky-500 duration-300"
                    onClick={(e) => {
                      setIsEditing(false);
                      handleEvent(selectedTitle, "Blocker", editedBlocker);
                      setShowPopup(false);
                    }}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="px-2 py-1 bg-sky-400 text-white font-semibold text-sm rounded hover:bg-sky-500 duration-300"
                    onClick={(e) => {
                      setIsEditing(true);
                      setEditedBlocker("");
                    }}
                  >
                    Edit
                  </button>
                )}
              </div>

              {/* otp */}
              <div className="flex justify-between items-end mb-1 mt-2 gap-2">
                <div className="w-[49%]">
                  <span className="font-semibold text-black flex">
                    Work Duration:{" "}
                  </span>
                  <select
                    value={selectedDuration || "Select"}
                    onChange={(e) => {
                      setSelectedDuration(e.target.value);
                      setChangeDuration(0);
                    }}
                    className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                  >
                    <option value="Select">Select</option>
                    <option value="2 hours">2 hours</option>
                    <option value="4 hours">4 hours</option>
                    <option value="1 day">1 day</option>
                    <option value="1.5 day">1.5 day</option>
                    <option value="2 days">2 days</option>
                    <option value="3 dasy">3 days</option>
                    <option value="4 dasy">4 days</option>
                    <option value="1 weeks">1 weeks</option>
                    <option value="2 weeks">2 weeks</option>
                  </select>
                </div>
                {changeDuration !== -1 &&
                  (generatedDurationOtp !== "" ? (
                    <div className="w-[49%]">
                      <div className="font-semibold text-black">Enter OTP:</div>
                      <div className="flex items-center gap-2 justify-between">
                        <input
                          className="w-full p-1.5 border border-gray-300 rounded"
                          placeholder="12345"
                          onChange={(e) => {
                            if (e.target.value.length === 5) {
                              verifyGeneratedOtp("duration", e.target.value);
                            }
                          }}
                          disabled={changeDuration === 1}
                          maxLength={5}
                        />
                        {changeDuration === 1 ? (
                          <svg
                            width="30px"
                            height="30px"
                            viewBox="-5 -5 93 93"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <circle
                                id="oval"
                                stroke="#43ac6a"
                                strokeWidth="9"
                                fill="transparent"
                                cx="41.5"
                                cy="41.5"
                                r="41.5"
                              ></circle>
                              <polygon
                                id="tick"
                                fill="#43ac6a"
                                points="66 29.01 35.03 59.97 16 40.94 22.92 34.02 35.03 46.13 59.08 22.09"
                              ></polygon>
                            </g>
                          </svg>
                        ) : (
                          <>
                            {resendDurationTimer === 0 ? (
                              <div
                                className="font-semibold underline underline-offset-2 text-xs md:text-sm text-[#5ab2ff] hover:text-[#3aa0ff] cursor-pointer"
                                onClick={() => {
                                  sendDurationOtp();
                                }}
                              >
                                Resend
                              </div>
                            ) : (
                              <div className="font-normal text-sm text-[#5ab2ff] hover:text-[#3aa0ff]">
                                {resendDurationTimer}s
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <button
                        className="w-[49%] px-2 py-2 bg-sky-400 text-white font-semibold text-md rounded hover:bg-sky-500 duration-300"
                        onClick={sendDurationOtp}
                      >
                        Send OTP
                      </button>
                    </>
                  ))}
              </div>
              <div className="flex justify-between items-end mb-1 mt-2 gap-2">
                <div className="w-[49%]">
                  <div className="font-semibold text-black">Rating:</div>
                  <select
                    value={selectedRate || "Select"}
                    onChange={(e) => {
                      setSelectedRate(e.target.value);
                      setChangeRate(0);
                    }}
                    className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                  >
                    <option value="Select">Select</option>
                    <option value="Exceeds Expectation">
                      Exceeds Expectation
                    </option>
                    <option value="Meets Expectation">Meets Expectation</option>
                    <option value="Not Meeting Expectation">
                      Not Meeting Expectation
                    </option>
                  </select>
                </div>
                {changeRate !== -1 &&
                  (generatedRatingOtp !== "" ? (
                    <div className="w-[49%]">
                      <div className="font-semibold text-black">Enter OTP:</div>
                      <div className=" w-full flex items-center gap-2 justify-between">
                        <input
                          className="w-full p-1.5 border border-gray-300 rounded"
                          placeholder="12345"
                          onChange={(e) => {
                            if (e.target.value.length === 5) {
                              verifyGeneratedOtp("rating", e.target.value);
                            }
                          }}
                          disabled={changeRate === 1}
                          maxLength={5}
                        />
                        {changeRate === 1 ? (
                          <svg
                            width="30px"
                            height="30px"
                            viewBox="-5 -5 93 93"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <circle
                                id="oval"
                                stroke="#43ac6a"
                                strokeWidth="9"
                                fill="transparent"
                                cx="41.5"
                                cy="41.5"
                                r="41.5"
                              ></circle>
                              <polygon
                                id="tick"
                                fill="#43ac6a"
                                points="66 29.01 35.03 59.97 16 40.94 22.92 34.02 35.03 46.13 59.08 22.09"
                              ></polygon>
                            </g>
                          </svg>
                        ) : (
                          <>
                            {resendRatingTimer === 0 ? (
                              <div
                                className=" cursor-pointer font-semibold underline underline-offset-2 text-xs md:text-sm text-[#5ab2ff] hover:text-[#3aa0ff]"
                                onClick={() => {
                                  sendRatingOtp();
                                }}
                              >
                                Resend
                              </div>
                            ) : (
                              <div className="font-normal text-sm text-[#5ab2ff] hover:text-[#3aa0ff]">
                                {resendRatingTimer}s
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <button
                        className="w-[49%] px-2 py-2 bg-sky-400 text-white font-semibold text-sm rounded hover:bg-sky-500 duration-300"
                        onClick={sendRatingOtp}
                      >
                        Send OTP
                      </button>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {show1 && (
        <div className="demo1-container ">
          <div className="bg-white w-[95%] md:w-[400px] lg:w-[450px] p-3 rounded-xl pop-up-animation">
            <div className="d-flex justify-content-end align-items-center">
              <button
                className="circle fromMiddle scale-90 left-2"
                data-animation="magnify"
                data-remove="3000"
                onClick={() => {
                  setShow1(false);
                  setUserOtp("");
                }}
              >
                <span></span>
              </button>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center p-3">
              <MdSecurityUpdateGood
                size={100}
                color="#fff"
                className="otp-icon"
              />
              <h6 className="text-center mt-4 font-semibold text-[#000000]">
                Are you sure you want to permanently delete this task{" "}
                <span className="font-bold text-blue-500">
                  {`${selectedTitle.slice(0, 50)}...`}
                </span>{" "}
                dated{" "}
                <span className="font-bold text-blue-500">
                  {formattedDate(selectedDate)}
                </span>
              </h6>
              <h6 className="text-sm text-center font-medium pb-2 mt-2">
                A code has been sent to xxxxxx{phone?.slice(-4)}
              </h6>
              <div
                id="otp"
                className="inputs d-flex flex-row justify-content-center mt-2 "
              >
                <input
                  className=" text-center border-2 rounded p-1.5"
                  type="number"
                  placeholder="Enter OTP"
                  value={userOtp}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setUserOtp(value);
                    }
                  }}
                />
              </div>
              <div className="mt-4 flex items-center gap-4">
                {loader2 ? (
                  <button className="btn btn-sm btn-primary w-[110px] flex justify-center items-center">
                    <img src="/images/btn-transparent.gif" className="h-4" />
                  </button>
                ) : (
                  <button
                    className="btn btn-sm btn-primary w-[110px]"
                    onClick={() => handleDeleteEvent(selectedTitle)}
                  >
                    Verify OTP
                  </button>
                )}

                {timer === 0 ? (
                  <button
                    className="  btn-sm rounded border w-[110px]"
                    onClick={() => {
                      sendDeleteOtp(selectedTitle);
                      setTimer(90);
                      handleTimer();
                    }}
                  >
                    Resend OTP
                  </button>
                ) : (
                  <button className="btn-sm rounded border w-[110px]">
                    <span className="text-xs">{formatTime(timer)}</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal === 1 ? (
        <AlertYellow
          message="Incorrect OTP!"
          onClose={() => setShowModal(-1)}
        />
      ) : showModal === 2 ? (
        <AlertGreen
          message="OTP is verified."
          onClose={() => setShowModal(-1)}
        />
      ) : showModal === 3 ? (
        <AlertYellow
          message="Mobile number must be of 10 digits"
          onClose={() => setShowModal(-1)}
        />
      ) : showModal === 4 ? (
        <AlertRed
          message="Mobile number provided is not Registered"
          onClose={() => setShowModal(-1)}
        />
      ) : (
        showModal === 5 && (
          <AlertRed
            message="Your task is not completed yet."
            onClose={() => setShowModal(-1)}
          />
        )
      )}
    </div>
  );
};

export default EventScheduler;
