import React, { useState, useEffect } from "react";
import { IoCloseCircle } from "react-icons/io5";
import "./admin.css";
import secureLocalStorage from "react-secure-storage";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import SchedulePopup from "./SchedulePopup";

const AdminCalender = ({ adminCalender, onReload }) => {
  let eventFormData = JSON.parse(secureLocalStorage.getItem("eventFormData"));
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [birthdays, setBirthdays] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const date = new Date();
    setCurrentDate(date);
    setCurrentMonth(date.getMonth());
    setCurrentYear(date.getFullYear());
  }, []);

  function formattedDate(date) {
    const newDate = new Date(date);
    const day = String(newDate.getDate()).padStart(2, "0");
    const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = String(newDate.getFullYear()).slice(-2); // Get the last two digits of the year
    return `${day}-${month}-${year}`;
  }

  const changeMonth = (direction) => {
    const newDate = new Date(currentYear, currentMonth + direction, 1);
    setCurrentMonth(newDate.getMonth());
    setCurrentYear(newDate.getFullYear());
  };

  const selectDate = (day) => {
    const newDate = new Date(currentYear, currentMonth, day);

    // Set events, coupons, and holidays
    const selectedDateStr = newDate.toLocaleDateString("en-CA");
    const couponsOnDate = [];
    const holidaysOnDate = [];
    const birthdaysOnDate = [];

    adminCalender.forEach((event) => {
      if (event.National_holidays) {
        Object.keys(event.National_holidays).forEach((val) => {
          if (new Date(val).toLocaleDateString("en-CA") === selectedDateStr) {
            holidaysOnDate.push({
              date: val,
              name: event.National_holidays[val],
            });
          }
        });
      } else if (event.BirthDays) {
        Object.keys(event.BirthDays).forEach((val) => {
          const birthday = new Date(val);
          const selectedDate = new Date(selectedDateStr);
          birthday.setFullYear(selectedDate.getFullYear());
          if (birthday.toLocaleDateString("en-CA") === selectedDateStr) {
            birthdaysOnDate.push({
              date: selectedDate.toISOString().split("T")[0],
              name: event.BirthDays[val],
            });
          }
        });
      } else if (event.title === "Next Coupon Payment Dates") {
        if (event.dates.includes(selectedDateStr)) {
          couponsOnDate.push({
            date: selectedDateStr,
            name: "Coupon Payment Day",
          });
        }
      }
    });

    setCoupons(couponsOnDate);
    setHolidays(holidaysOnDate);
    setBirthdays(birthdaysOnDate);

    setCurrentDate(newDate);

    // Create a new Date object for the next day
    const nextDay = new Date(newDate);
    setSelectedDate(nextDay.setDate(nextDay.getDate() + 1));
  };

  const renderDays = () => {
    let firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
    if (firstDayOfMonth === 0) firstDayOfMonth = 7;

    const lastDateOfMonth = new Date(
      currentYear,
      currentMonth + 1,
      0
    ).getDate();
    const lastDateOfPrevMonth = new Date(
      currentYear,
      currentMonth,
      0
    ).getDate();

    const days = [];
    for (let i = firstDayOfMonth - 2; i >= 0; i--) {
      days.push(
        <div
          key={`prev-${i}`}
          className="w-full text-center text-sm md:text-base 2xl:text-lg py-1 font-bold my-1 mx-2 cursor-pointer next-month"
        >
          {lastDateOfPrevMonth - i}
        </div>
      );
    }

    const isDateHighlighted = (date) => {
      const dates = [];
      if (adminCalender) {
        adminCalender.forEach((event) => {
          if (event.National_holidays) {
            Object.keys(event.National_holidays).forEach((val) => {
              dates.push(val);
            });
          }
        });
      }
      let dateStr = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      dateStr = dateStr.toLocaleString().replace(", ", " ");
      return dates.includes(dateStr);
    };

    const couponHighlighted = (date) => {
      const couponDates = [];
      if (adminCalender) {
        adminCalender.forEach((event) => {
          if (event.title === "Next Coupon Payment Dates") {
            event.dates.forEach((val) => {
              couponDates.push(val);
            });
          }
        });
      }
      let dateStr = date.toLocaleDateString("en-CA");
      return couponDates.includes(dateStr);
    };

    const birthDayHighlighted = (date) => {
      const dates = [];
      if (adminCalender) {
        adminCalender.forEach((event) => {
          if (event.BirthDays) {
            Object.keys(event.BirthDays).forEach((val) => {
              dates.push(val);
            });
          }
        });
      }

      const dateStr = date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });

      return dates.some((birthday) => {
        const birthdayWithoutYear = new Date(birthday).toLocaleDateString(
          "en-US",
          {
            month: "long",
            day: "numeric",
          }
        );
        return birthdayWithoutYear === dateStr;
      });
    };

    // Inside the rendering loop
    for (let i = 1; i <= lastDateOfMonth; i++) {
      const date = new Date(currentYear, currentMonth, i);
      const isSunday = date.getDay() === 0;
      const isHighlighted = isDateHighlighted(date);
      const coupon = couponHighlighted(date);
      const birthdays = birthDayHighlighted(date); // Get all event colors

      days.push(
        <div
          key={`current-${i}`}
          className={`w-full border-2 hover:border-[#1DA6C6] text-center text-sm md:text-base 2xl:text-lg relative py-1 font-bold my-1 mx-2 cursor-pointer rounded-lg ${
            i === currentDate.getDate() &&
            currentMonth === currentDate.getMonth() &&
            currentYear === currentDate.getFullYear()
              ? "border-2 border-[#1DA6C6] shadow-md"
              : ""
          } ${
            date.toDateString() === new Date().toDateString()
              ? "border-2 border-orange-400 shadow-md"
              : ""
          } ${isSunday && "sunday"} ${
            isSunday &&
            i === currentDate.getDate() &&
            currentMonth === currentDate.getMonth() &&
            currentYear === currentDate.getFullYear() &&
            "border-2 border-[#DC4C64] shadow-md"
          }`}
          onClick={() => {
            selectDate(i);
            if (birthdays || coupon || isHighlighted) {
              setShow2(true);
            } else {
              setShow(true);
            }
          }}
        >
          {isHighlighted && (
            <img className="highlighted" src="/images/holidays.svg" />
          )}
          {coupon && (
            <img
              className="coupon-highlighted"
              src="/images/coupons-days.svg"
            />
          )}
          {birthdays && (
            <img className="birthday-highlighted" src="/images/birthdays.svg" />
          )}
          {i}
        </div>
      );
    }

    const extraDays = 7 - (days.length % 7);
    for (let i = 1; i <= extraDays && extraDays < 7; i++) {
      days.push(
        <div
          key={`next-${i}`}
          className="w-full text-center text-sm md:text-base 2xl:text-lg py-1 font-bold my-1 mx-2 cursor-pointer next-month rounded-lg "
        >
          {i}
        </div>
      );
    }

    return days;
  };

  useEffect(() => {
    if (show || show2) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show, show2]);

  return (
    <>
      {show && (
        <>
          <SchedulePopup
            date={selectedDate}
            onReload={(val) => onReload(val)}
            onClose={() => setShow(false)}
          />
        </>
      )}
      {show2 && (
        <>
          <div className="popup-container">
            <div className="flex flex-col justify-center items-center relative bg-white border p-4 rounded-lg pop-up-animation max-md:w-screen max-md:mx-2 md:w-[500px]">
              <img src="/images/contact-popup.png" alt="" className="w-32" />
              <div className="flex flex-col items-center text-base font-bold mt-2 gap-2">
                {/* birthdays */}
                {birthdays.length > 0 && (
                  <>
                    <div className="flex items-center px-2.5 text-black text-base xl:text-lg font-bold rounded-t-lg">
                      <span className="h-3 w-3 bg-[#4cdc94] border rounded-full  mr-3"></span>
                      <span>Birthdays</span>
                    </div>
                    <div
                      className="overflow-y-scroll p-2 max-h-[260px] flex flex-col gap-2 
border rounded-md"
                    >
                      {birthdays.map((birthday, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center pl-2 h-full"
                        >
                          <div className="flex justify-start items-center w-full py-1 max-h-[250px]">
                            <span className="text-xs text-primary font-bold">
                              {formattedDate(birthday.date)} : &nbsp;
                            </span>
                            <span className="text-xs font-bold">
                              {birthday.name}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {/*  */}
                {/* holidays */}
                <div className="flex items-center px-2.5 text-black text-base xl:text-lg font-bold rounded-t-lg">
                  <span className="h-3 w-3 bg-[#DC4C64] border rounded-full  mr-3"></span>
                  <span>Holidays</span>
                </div>
                <div
                  className="overflow-y-scroll p-2  max-h-[260px] flex flex-col gap-2 
border rounded-md"
                >
                  {holidays.length > 0 ? (
                    holidays.map((holiday, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center pl-2 h-full"
                      >
                        <div className="flex justify-start items-center w-full py-1 max-h-[250px]">
                          <span className="text-xs text-primary font-bold mr-2">
                            {formattedDate(holiday.date)} :&nbsp;
                          </span>
                          <span className="text-xs  font-bold">
                            {holiday.name}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex py-1 text-[#9c9c9c] text-xs justify-between items-center pl-2 h-full">
                      No Holidays
                    </div>
                  )}
                </div>
                {/*  */}
                {/* coupons */}
                <div className="flex items-center px-2.5 text-black text-base xl:text-lg font-bold rounded-t-lg">
                  <span className="h-3 w-3 bg-[#4cdc94] border rounded-full  mr-3"></span>
                  <span>Coupons</span>
                </div>
                <div
                  className="overflow-y-scroll p-2 max-h-[260px] flex flex-col gap-2 
border rounded-md"
                >
                  {coupons.length > 0 ? (
                    coupons.map((coupon, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center pl-2 h-full"
                      >
                        <div className="flex justify-start items-center w-full py-1 max-h-[250px]">
                          <span className="text-xs text-primary font-bold">
                            {formattedDate(coupon.date)} : &nbsp;
                          </span>
                          <span className="text-xs font-bold">
                            {coupon.name}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex py-1 text-[#9c9c9c] text-xs justify-between items-center pl-2 h-full">
                      No Coupons
                    </div>
                  )}
                </div>
              </div>
              {/* Close button */}
              <span
                className="absolute top-3 right-3 hover:bg-zinc-200 p-1 rounded-full duration-200 cursor-pointer"
                onClick={() => setShow2(false)}
              >
                <span className="text-lg text-black">
                  <IoCloseCircle />
                </span>
              </span>
            </div>
          </div>
        </>
      )}

      <div className="admin-calender border shadow-md rounded-lg h-fit min-h-[400px]">
        <div className="flex flex-wrap items-center justify-between rounded-t-lg bg-[#1DA6C6] px-2 xl:px-3 py-2">
          {/* Calender Head */}
          <div className="flex justify-between items-center min-w-[200px] xl:w-[40%]">
            <div
              className="rounded-full h-fit cursor-pointer text-white text-lg xl:text-xl flex items-center justify-center scale-100 hover:scale-[95%] duration-100 ease-in-out"
              onClick={() => changeMonth(-1)}
            >
              <IoIosArrowDropleftCircle />
            </div>

            <div className="text-lg xl:text-xl font-bold text-white">{`${new Intl.DateTimeFormat(
              "en-us",
              {
                month: "long",
              }
            ).format(
              new Date(currentYear, currentMonth)
            )} ${currentYear}`}</div>

            <div
              className="rounded-full h-fit cursor-pointer text-white text-lg xl:text-xl flex items-center justify-center scale-100 hover:scale-[95%] duration-100 ease-in-out"
              onClick={() => changeMonth(1)}
            >
              <IoIosArrowDroprightCircle />
            </div>
          </div>

          <div
            className="flex items-center h-9 w-auto xl:w-[100px] bg-zinc-100 px-1.5 py-1 justify-between gap-1 rounded-md cursor-pointer scale-100 hover:scale-[95%] duration-100 ease-in-out"
            onClick={() => setShow(true)}
          >
            <span>
              <img
                src="/images/events.svg"
                className="w-6 xl:w-7 h-fit"
                alt=""
              />
            </span>
            <span className="text-[11px] xl:text-xs  font-semibold duration-200">
              <p className="leading-3">Schedule</p>
              <p>Event</p>
            </span>
          </div>
        </div>
        {/* Mon to Sun */}
        <div className="w-100 d-flex flex-column justify-content-around">
          <div className="flex justify-around items-center text-black  text-sm md:text-base 2xl:text-lg">
            <div className="w-[10%] text-center p-2 font-bold">Mon</div>
            <div className="w-[10%] text-center p-2 font-bold">Tue</div>
            <div className="w-[10%] text-center p-2 font-bold">Wed</div>
            <div className="w-[10%] text-center p-2 font-bold">Thur</div>
            <div className="w-[10%] text-center p-2 font-bold">Fri</div>
            <div className="w-[10%] text-center p-2 font-bold">Sat</div>
            <div className="w-[10%] text-center p-2 font-bold text-danger">
              Sun
            </div>
          </div>
          <hr className="mb-2" />
          <div className="flex justify-around items-center">
            {renderDays().slice(0, 7)}
          </div>
          <div className="flex justify-around items-center">
            {renderDays().slice(7, 14)}
          </div>
          <div className="flex justify-around items-center">
            {renderDays().slice(14, 21)}
          </div>
          <div className="flex justify-around items-center">
            {renderDays().slice(21, 28)}
          </div>
          <div className="flex justify-around items-center">
            {renderDays().slice(28, 35)}
          </div>
          {renderDays().length > 35 && (
            <div className="flex justify-around items-center">
              {renderDays().slice(35)}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminCalender;
