import React, { useState } from "react";
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi";
import { IoPrintSharp } from "react-icons/io5";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import "./CalComponent.css";
import secureLocalStorage from "react-secure-storage";

function CouponStatements({ head }) {
  const { email, phone, pan, walr } = CheckAuth();
  const [selectedTab, setSelectedTab] = useState("walr");
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * 8;
  const endIndex = startIndex + 8;
  const storedData = secureLocalStorage.getItem("couponData");
  const couponData = JSON.parse(storedData);
  const [Fw, setFw] = useState(0.5);

  const totalPages = Math.ceil(
    Object.keys(couponData || {}).filter((key) => key !== "pan").length / 8
  );

  const handleChange = (event) => {
    setSelectedTab(event.target.id);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const filterData = () => {
    if (!couponData) return [];
    const selectedTabUpper = selectedTab.toUpperCase();
    const sortedData = Object.entries(couponData)
      .filter(([date, data]) => date !== "pan" && data[selectedTabUpper])
      .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA));
    const filteredData = sortedData.map(([date, data]) => ({
      date,
      ...data[selectedTabUpper],
    }));
    return filteredData.slice(startIndex, endIndex);
  };

  const currentTransactions = filterData();

  return (
    <>
      <div className=" col-md-12 col-lg-9 col-sm-12 py-2 lg:pl-8 max-md:px-3 md:px-2.5">
        <div id="coupon-table" className="border p-3 shadow-3 rounded-lg row">
          <div className="flex flex-wrap justify-between items-center gap-2 m-0 p-0">
            {/* Heading */}
            <span className="text-xl font-bold text-black">{head}</span>

            {/* Buttons */}
            <div className="w-full md:w-fit flex justify-center">
              <div className="segmented-control relative flex items-center border shadow-sm rounded-lg w-52 h-12 px-1">
                <input
                  type="radio"
                  name="radio2"
                  value="3"
                  id="walr"
                  className="hidden"
                  checked={selectedTab === "walr"}
                  onChange={handleChange}
                />
                <label
                  htmlFor="walr"
                  className="segmented-control__1 flex-1 flex justify-center items-center text-gray-600 transition-all duration-500 cursor-pointer"
                  style={{
                    width: "5rem",
                  }}
                >
                  <p className="m-0 font-bold text-sm">WALR</p>
                </label>

                <input
                  type="radio"
                  name="radio2"
                  value="4"
                  id="residex"
                  className="hidden"
                  checked={selectedTab === "residex"}
                  onChange={handleChange}
                />
                <label
                  htmlFor="residex"
                  className="segmented-control__2 flex-1 flex justify-center items-center text-gray-600 transition-all duration-500 cursor-pointer z-1"
                  style={{
                    width: "7.6rem",
                  }}
                >
                  <p className="m-0 font-bold text-sm">RESIDEX</p>
                </label>

                <div
                  className="absolute h-10 z-0 rounded-lg bg-[#d8e5f969] shadow-inner pointer-events-none transition-transform duration-300 ease-in-out"
                  style={{
                    transform:
                      selectedTab === "walr"
                        ? "translateX(0)"
                        : selectedTab === "residex"
                        ? "translateX(6.3rem)"
                        : "translateX(13.6rem)",
                    width:
                      selectedTab === "walr"
                        ? "6rem"
                        : selectedTab === "residex"
                        ? "6rem"
                        : "0rem",
                  }}
                ></div>
              </div>
            </div>

            {/* Print Option */}
            <div className="flex items-center space-x-2">
              <button
                className="flex items-center text-xs font-semibold bg-[#386BC0] px-3 py-1 rounded-full text-white hover:scale-95 duration-200"
                onClick={() => window.print()}
              >
                <IoPrintSharp />
                &nbsp;Print
              </button>
              <div className="flex items-center justify-between">
                {/* Multiple Pages */}
                {currentPage > 1 ? (
                  <>
                    <div
                      className="btn btn-sm p-0 btn-secondary flex items-center"
                      onClick={() => handlePrevPage()}
                    >
                      <BiSkipPrevious size={25} color="#000" />
                    </div>
                    <div className="mx-3 my-0">{currentPage - 1}</div>
                  </>
                ) : (
                  <>
                    <div className="">
                      <BiSkipPrevious size={25} color="#fff" />
                    </div>
                    <div className="mx-3 my-0 text-light">{currentPage}</div>
                  </>
                )}
                <div className="mx-1 my-0 px-2 py-0 rounded border-0 bg-blue-200">
                  {currentPage}
                </div>
                {currentPage < totalPages ? (
                  <>
                    <div className="mx-3 my-0">{currentPage + 1}</div>

                    <div
                      className="btn p-0 btn-sm btn-secondary flex items-center"
                      onClick={() => handleNextPage(1)}
                    >
                      <BiSkipNext size={25} color="#000" />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mx-3 my-0 text-light">{currentPage}</div>

                    <div className="">
                      <BiSkipNext size={25} color="#fff" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <hr className="m-0 p-0 my-2" />

          {/* Transaction Table */}
          <div className="row m-0 p-0 overflow-scroll min-h-[320px]">
            <table className="table-responsive table table-hover h-fit table-borderless table-sm border-dark min-w-[500px]">
              <thead className="rounded">
                <tr
                  className="font-semibold bg-[#386BC0] text-white"
                  style={{ backgroundColor: "" }}
                >
                  <th
                    scope="col"
                    className="w-[25%] text-xs 2xl:text-[13px] fw-bolder text-center"
                  >
                    Payment Date
                  </th>
                  <th
                    scope="col"
                    className="w-[25%] text-xs 2xl:text-[13px] fw-bolder text-center"
                  >
                    Networth (₹)
                  </th>
                  <th
                    scope="col"
                    className="w-[25%] text-xs 2xl:text-[13px] fw-bolder text-center"
                  >
                    {selectedTab === "walr" ? "Covered" : "Index"} (₹)
                  </th>
                  <th
                    scope="col"
                    className="w-[25%] text-xs 2xl:text-[13px] fw-bolder text-center"
                  >
                    {selectedTab === "walr" ? "Naked" : "Futures"} (₹)
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(currentTransactions) &&
                !currentTransactions.length > 0 ? (
                  currentTransactions.map((data, index) => (
                    <tr
                      key={index}
                      className="shadow-sm text-black font-semibold hover:scale-105 duration-100 cursor-pointer hover:font-extrabold hover:bg-[#D8E5F9]"
                    >
                      <td className="w-[25%] text-center text-xs 2xl:text-[13px]">
                        {data.date}
                      </td>
                      <td className="w-[25%] text-center text-xs 2xl:text-[13px]">
                        {((data?.Management * 400) / Fw || 0).toLocaleString(
                          "en-IN"
                        )}
                      </td>
                      <td className="w-[25%] text-center text-xs 2xl:text-[13px]">
                        {selectedTab === "walr" ? (
                          <>
                            {Number(
                              (data?.CoverYes || 0)?.toFixed(2)
                            ).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                            })}
                          </>
                        ) : (
                          <>
                            {Number(
                              (data?.Index || 0)?.toFixed(2)
                            ).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                            })}
                          </>
                        )}
                      </td>
                      <td className="w-[25%] text-center text-xs 2xl:text-[13px]">
                        {selectedTab === "walr" ? (
                          <>
                            {Number(
                              (data?.CoverNo || 0)?.toFixed(2)
                            ).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                            })}
                          </>
                        ) : (
                          <>
                            {Number(
                              (data?.Futures || 0)?.toFixed(2)
                            ).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                            })}
                          </>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border-top">
                    <td
                      colSpan={4}
                      className="text-center text-xs 2xl:text-[13px]"
                    >
                         
                        <div className="animate-pulse flex flex-col justify-center items-center px-2 gap-y-4 overflow-hidden ">
                    <img
                      className="w-32 h-32 "
                      src="/images/no-info.svg"
                      alt=""
                    />
                    <span className="text-center text-xs md:text-sm   font-semibold">
                      {" "}
                      You don't have any Coupon Statements.
                    </span>
                  </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default CouponStatements;
